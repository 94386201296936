import React, { useEffect, useState } from "react";
import {
  TextInput,
  Textarea,
  Select,
  Stack,
  ActionIcon,
  Box,
  Text,
  Loader,
} from "@mantine/core";
import { ActionType } from "../../gql/graphql";
import { IconCheck, IconX, IconSettings } from "@tabler/icons-react";
import { getSchemaForActionType } from "./action/validator";
import Ajv from "ajv";
import { useForm } from "@mantine/form";
import { ProcessEntityConfigForm } from "./ProcessEntityConfigForm";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_PROCESS_CONFIG_BY_ENTITY } from "../../shared/queries";
import {
  CREATE_PROCESS_CONFIG,
  UPDATE_PROCESS_CONFIG,
} from "../../shared/mutations";

// Props interface for the ActionForm component
interface ActionFormProps {
  onSave: (action: any) => void; // Callback function when form is submitted
  onCancel: () => void; // Callback function when form is cancelled
  initialState: "ADD" | "EDIT"; // Whether we're adding a new action or editing an existing one
  selectedRow?: any; // The existing action data when in EDIT mode
}

export const ActionForm: React.FC<ActionFormProps> = ({
  onSave,
  onCancel,
  initialState,
  selectedRow,
}) => {
  const [isEditing] = useState(initialState === "EDIT");
  const [validationError, setValidationError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfigForm, setShowConfigForm] = useState(false);
  const [configFormState, setConfigFormState] = useState<"ADD" | "EDIT">("ADD");
  const [configData, setConfigData] = useState<any>(null);

  const [getProcessConfig, { loading: configLoading }] = useLazyQuery(
    GET_PROCESS_CONFIG_BY_ENTITY,
    {
      onCompleted: (data) => {
        if (data && data.processConfigByEntity) {
          setConfigData(data.processConfigByEntity);
          setConfigFormState("EDIT");
        } else {
          setConfigData(null);
          setConfigFormState("ADD");
        }
        setShowConfigForm(true);
      },
      onError: (error) => {
        console.error("Error fetching process config:", error);
        setConfigData(null);
        setConfigFormState("ADD");
        setShowConfigForm(true);
      },
    }
  );

  const [createProcessConfig] = useMutation(CREATE_PROCESS_CONFIG);
  const [updateProcessConfig] = useMutation(UPDATE_PROCESS_CONFIG);

  const form = useForm({
    initialValues:
      initialState === "EDIT" && selectedRow
        ? {
            id: selectedRow.id,
            title: selectedRow.title,
            description: selectedRow.description || "",
            actionType: selectedRow.actionType as ActionType,
            actionConfiguration:
              typeof selectedRow.actionConfiguration === "string"
                ? JSON.parse(selectedRow.actionConfiguration)
                : selectedRow.actionConfiguration || {},
            state: "EDIT",
          }
        : {
            id: null,
            title: "",
            description: "",
            actionType: null as ActionType | null,
            actionConfiguration: {},
            state: "ADD",
          },
  });

  // Validates the configuration against the schema
  const validateConfig = (
    config: Record<string, string>,
    schema: any
  ): boolean => {
    const ajv = new Ajv();
    const validate = ajv.compile(schema);
    const isValid = validate(config);

    if (!isValid) {
      const errors = validate.errors;
      if (errors && errors.length > 0) {
        // Format validation errors into a readable message
        const errorMessage = errors
          .map((err) => {
            const field =
              err.instancePath.replace("/", "") || err.params.missingProperty;
            return `${field}: ${err.message}`;
          })
          .join(", ");
        setValidationError(errorMessage);
        return false;
      }
    }

    setValidationError(null);
    return true;
  };

  const handleSave = async () => {
    try {
      const validation = form.validate();
      if (!validation.hasErrors) {
        if (!form.values.actionType) return;

        const schema = getSchemaForActionType(
          form.values.actionType.toString()
        );
        if (!schema) return;

        if (validateConfig(form.values.actionConfiguration, schema)) {
          const formData = {
            ...form.values,
            actionConfiguration: JSON.stringify(
              form.values.actionConfiguration
            ),
          };
          onSave(formData);
          onCancel();
        }
      }
    } catch (error) {
      console.error("Validation error:", error);
    }
  };

  const handleCancel = () => {
    form.reset();
    onCancel();
  };

  // Available action types for the select dropdown
  const actionTypeOptions = [
    { value: ActionType.Lambda, label: "Lambda Function" },
    { value: ActionType.LaunchProcess, label: "Launch Process" },
    { value: ActionType.Prompt, label: "User Prompt" },
  ];

  // Handles action type selection changes
  const handleActionTypeChange = (value: string | null) => {
    form.setFieldValue("actionType", value as ActionType);
    form.setFieldValue("actionConfiguration", {});
    setValidationError(null);
  };

  // Handles changes to configuration fields
  const handleConfigChange = (field: string, value: string) => {
    form.setFieldValue("actionConfiguration", {
      ...form.values.actionConfiguration,
      [field]: value,
    });
    setValidationError(null);
  };

  // Dynamically renders configuration fields based on the selected action type
  const renderConfigFields = () => {
    if (!form.values.actionType) return null;

    const schema = getSchemaForActionType(form.values.actionType);
    if (!schema) return null;

    const properties = schema.properties as Record<string, any>;
    const required = schema.required as string[];

    return Object.entries(properties).map(([field, fieldSchema]) => {
      // Use Textarea for long text fields (maxLength > 100)
      const isLongText = fieldSchema.maxLength && fieldSchema.maxLength > 100;
      const commonProps = {
        key: field,
        label: fieldSchema.title || fieldSchema.description || field,
        value: form.values.actionConfiguration[field] || "",
        onChange: (
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleConfigChange(field, e.target.value),
        required: required.includes(field),
        placeholder: `Enter ${fieldSchema.description || field}`,
        error: validationError?.includes(field)
          ? validationError
              .split(", ")
              .find((err) => err.startsWith(field))
              ?.split(": ")[1]
          : undefined,
      };

      return isLongText ? (
        <Textarea {...commonProps} minRows={3} maxRows={6} />
      ) : (
        <TextInput {...commonProps} />
      );
    });
  };

  const toggleConfigForm = () => {
    if (!showConfigForm && selectedRow?.id) {
      console.log(
        "Fetching config for entity:",
        selectedRow.id,
        "Type: ACTION"
      );
      getProcessConfig({
        variables: {
          entityId: selectedRow.id,
          entityType: "ACTION",
        },
        fetchPolicy: "network-only",
      });
    } else {
      setShowConfigForm(!showConfigForm);
    }
  };

  const handleConfigSave = async (values: any) => {
    try {
      if (configFormState === "ADD") {
        await createProcessConfig({
          variables: {
            entityId: selectedRow.id,
            entityType: "ACTION",
            inputSource: values.inputSource,
            inputSourceConfiguration: values.inputSourceConfiguration,
            inputDescription: values.inputDescription,
            inputSample: values.inputSample,
            inputValidationType: values.inputValidationType,
            inputValidation: values.inputValidation,
            outputDescription: values.outputDescription,
            outputSample: values.outputSample,
            outputValidationType: values.outputValidationType,
            outputValidation: values.outputValidation,
          },
          onCompleted: (data) => {
            console.log("Config created successfully:", data);
            if (data && data.createProcessConfig) {
              setConfigData(data.createProcessConfig);
              setConfigFormState("EDIT");
            }
            setShowConfigForm(false);
          },
          onError: (error) => {
            console.error("Error creating process config:", error);
          },
          refetchQueries: [
            {
              query: GET_PROCESS_CONFIG_BY_ENTITY,
              variables: {
                entityId: selectedRow.id,
                entityType: "ACTION",
              },
            },
          ],
        });
      } else {
        await updateProcessConfig({
          variables: {
            id: values.id,
            inputSource: values.inputSource,
            inputSourceConfiguration: values.inputSourceConfiguration,
            inputDescription: values.inputDescription,
            inputSample: values.inputSample,
            inputValidationType: values.inputValidationType,
            inputValidation: values.inputValidation,
            outputDescription: values.outputDescription,
            outputSample: values.outputSample,
            outputValidationType: values.outputValidationType,
            outputValidation: values.outputValidation,
          },
          onCompleted: (data) => {
            console.log("Config updated successfully:", data);
            if (data && data.updateProcessConfig) {
              setConfigData(data.updateProcessConfig);
            }
            setShowConfigForm(false);
          },
          onError: (error) => {
            console.error("Error updating process config:", error);
          },
          refetchQueries: [
            {
              query: GET_PROCESS_CONFIG_BY_ENTITY,
              variables: {
                entityId: selectedRow.id,
                entityType: "ACTION",
              },
            },
          ],
        });
      }
    } catch (error) {
      console.error("Error saving process config:", error);
    }
  };

  // Main form render
  return (
    <Stack gap="24">
      <Box component="form" onSubmit={form.onSubmit(handleSave)} py={24}>
        <Stack gap={24}>
          <input type="hidden" {...form.getInputProps("id")} />
          <input type="hidden" {...form.getInputProps("state")} />

          <TextInput
            label="Title"
            required
            {...form.getInputProps("title")}
            error={form.errors.title && "Please enter a title"}
          />

          <Textarea
            label="Description"
            {...form.getInputProps("description")}
          />

          <Select
            label="Action Type"
            value={form.values.actionType}
            onChange={(value) => handleActionTypeChange(value as string)}
            data={actionTypeOptions}
            required
          />

          {isLoading ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px",
              }}
            >
              <Loader size="sm" />
            </Box>
          ) : (
            renderConfigFields()
          )}

          {validationError && (
            <Text color="red" size="sm">
              {validationError}
            </Text>
          )}

          <Box
            style={{
              display: "flex",
              gap: "8px",
              justifyContent: "space-between",
            }}
          >
            <Box style={{ display: "flex", gap: "8px" }}>
              {(initialState !== "EDIT" || form.isDirty()) && (
                <>
                  <ActionIcon
                    type="submit"
                    variant="filled"
                    color="blue"
                    disabled={initialState === "ADD" && !form.isDirty()}
                    aria-label={isEditing ? "Save Changes" : "Add Action"}
                  >
                    <IconCheck size="1.125rem" />
                  </ActionIcon>
                  <ActionIcon
                    variant="outline"
                    color="red"
                    onClick={handleCancel}
                    disabled={initialState === "ADD" && !form.isDirty()}
                    aria-label={isEditing ? "Undo" : "Cancel"}
                  >
                    <IconX size="1.125rem" />
                  </ActionIcon>
                </>
              )}
            </Box>

            <ActionIcon
              variant="outline"
              color="gray"
              aria-label="Settings"
              onClick={toggleConfigForm}
            >
              <IconSettings size="1.125rem" />
            </ActionIcon>
          </Box>
        </Stack>
      </Box>

      {showConfigForm && (
        <Box mt={0}>
          <ProcessEntityConfigForm
            onSave={handleConfigSave}
            onCancel={() => setShowConfigForm(false)}
            initialState={configFormState}
            selectedRow={configData}
          />
        </Box>
      )}
    </Stack>
  );
};
