import React from "react";
import { TenantSpaces } from "./pages/entities/TenantSpaces";
import { SpaceDetails } from "./pages/entities/SpaceDetails";
import { ProcessDetails } from "./pages/entities/ProcessDetails";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppLayout } from "./AppLayout";
import { SpaceContext } from "./contexts/SpaceContext";
import { ProcessContext } from "./contexts/ProcessContext";
import { GmailIInbox } from "./tests/gmail";
import { RedirectTo } from "./addon/retirectTo";
import { MantineProvider, createTheme } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";

import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "mantine-datatable/styles.css";
import "@mantine/tiptap/styles.css";
import "./styles/mantine-override.css";

function App() {
  const [space, setSpace] = React.useState({ cSpaceId: "", cSpaceName: "" });
  const [process, setProcess] = React.useState({
    cProcessId: "",
    cProcessName: "",
  });

  // Mantine theme config
  const theme = createTheme({
    fontFamily: "Montserrat",
  });

  return (
    <MantineProvider theme={theme}>
      <ModalsProvider modalProps={{ zIndex: 999999 }}>
        <BrowserRouter>
          <SpaceContext.Provider value={{ space, setSpace }}>
            <ProcessContext.Provider value={{ process, setProcess }}>
              {/* Add the Routes component */}
              <Routes>
                <Route
                  path="/"
                  element={
                    <AppLayout pageTitle="Spaces">
                      <TenantSpaces />
                    </AppLayout>
                  }
                />
                <Route
                  path="/tenant/:tenantId"
                  element={
                    <AppLayout pageTitle="Shared Tenant">
                      <TenantSpaces />
                    </AppLayout>
                  }
                />
                <Route
                  path="/space/:spaceId"
                  element={
                    <AppLayout pageTitle={space.cSpaceName}>
                      <SpaceDetails />
                    </AppLayout>
                  }
                />

                <Route
                  path="/process/:processId"
                  element={
                    <AppLayout pageTitle={process.cProcessName}>
                      <ProcessDetails />
                    </AppLayout>
                  }
                />
                <Route
                  path="/addon_redirect/:redirect_to"
                  element={<RedirectTo />}
                />
                <Route path="/test/gmail" element={<GmailIInbox />} />
              </Routes>
            </ProcessContext.Provider>
          </SpaceContext.Provider>
        </BrowserRouter>
      </ModalsProvider>
    </MantineProvider>
  );
}

export default App;
