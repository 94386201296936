import { Badge } from "@mantine/core";
import styles from "./StatusField.module.css";

interface StatusColumnFieldProps {
  status: string;
  currentProcedure?: string;
}

export function StatusField({
  status,
  currentProcedure,
}: StatusColumnFieldProps) {
  const getStatusColor = (status: string) => {
    switch (status) {
      case "To_Do":
        return "#C5F0FF";
      case "In_Progress":
        return "#E5C9FF";
      case "Paused":
        return "yellow";
      case "Skipped":
        return "red";
      case "Done":
        return "#89FA60";
      default:
        return "gray";
    }
  };

  // Convert the status to a string
  const normalizedStatus = status.replace(/_/g, " ");

  // Capitalize the first letter of each word
  // const displayText = normalizedStatus
  //   .split(" ")
  //   .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
  //   .join(" ");

  console.log(normalizedStatus);
  return (
    <Badge
      tt="none"
      autoContrast
      radius="xl"
      className={styles.statusBadge}
      color={getStatusColor(status)}
    >
      {" " + normalizedStatus + " "}
    </Badge>
  );
}
