import React, { useEffect } from "react";
import { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { CSS } from "@dnd-kit/utilities";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  Accordion,
  Group,
  Stack,
  Modal,
  ActionIcon,
  Menu,
} from "@mantine/core";
import {
  IconGripVertical,
  IconPlus,
  IconDotsVertical,
  IconTrash,
} from "@tabler/icons-react";
import { ActionForm } from "../forms/ActionForm";
import {
  CREATE_ACTION,
  UPDATE_ACTION,
  DELETE_ACTION,
} from "../../shared/mutations";
import { openDeleteConfirmation } from "./Components/DeleteEntityConfirmation";

export interface ActionTableDataType {
  key: string;
  id: string;
  title: string;
  description: string;
  actionType: string;
  actionConfiguration: any;
}

interface EntityTableProps {
  title: string;
  stepId: string;
}

const GET_STEP_ACTIONS = gql`
  query GetStepActions($stepId: ID!) {
    actionsByStepId(stepId: $stepId) {
      id
      title
      description
      actionType
      actionConfiguration
    }
  }
`;

export function useCreateActionMutation() {
  return useMutation(CREATE_ACTION);
}

export function useUpdateActionMutation() {
  return useMutation(UPDATE_ACTION);
}

export function useDeleteActionMutation() {
  return useMutation(DELETE_ACTION);
}

export const ActionsTable: React.FC<EntityTableProps> = ({ title, stepId }) => {
  const ModalStates = {
    OFF: "OFF",
    ADD: "ADD",
    EDIT: "EDIT",
  };
  const [tableSource, setTableSource] = useState<ActionTableDataType[]>([]);
  const [modalState, setModalState] = useState(ModalStates.OFF);
  const [selectedRow, setSelectedRow] = useState<ActionTableDataType>();

  const [createActionMutation] = useCreateActionMutation();
  const [updateActionMutation] = useUpdateActionMutation();
  const [deleteActionMutation] = useDeleteActionMutation();

  const { loading, error, data } = useQuery(GET_STEP_ACTIONS, {
    variables: { stepId },
  });

  useEffect(() => {
    if (!loading && !error && data) {
      setTableSource(
        data.actionsByStepId.map((action: any) => ({
          key: action.id,
          id: action.id,
          title: action.title,
          description: action.description,
          actionType: action.actionType,
          actionConfiguration: action.actionConfiguration,
        })) || []
      );
    }
  }, [data, loading, error]);

  const handleCancel = () => {
    setModalState(ModalStates.OFF);
  };

  const handleAddAction = () => {
    setModalState(ModalStates.ADD);
  };

  const handleEditAction = (entity: any) => {
    setSelectedRow(entity);
    setModalState(ModalStates.EDIT);
  };

  const handleDeleteActionClick = (entity: any) => {
    openDeleteConfirmation(entity.title, "Action", async () => {
      await handleDeleteAction(entity);
    });
  };

  const handleDeleteAction = async (action: any) => {
    try {
      const result = await deleteActionMutation({
        variables: {
          id: action.id,
        },
      });

      setTableSource(tableSource.filter((row) => row.key !== action.id));
      return true;
    } catch (error) {
      console.error("Mutation error:", error);
      return false;
    }
  };

  const handleSaveAction = async (action: any) => {
    if (action.state === ModalStates.ADD) {
      try {
        const result = await createActionMutation({
          variables: {
            title: action.title,
            description: action.description,
            actionType: action.actionType,
            actionConfiguration: action.actionConfiguration,
            stepId: stepId,
          },
        });

        const newData: ActionTableDataType = {
          key: result.data.createAction.id,
          id: result.data.createAction.id,
          title: result.data.createAction.title,
          description: result.data.createAction.description,
          actionType: result.data.createAction.actionType,
          actionConfiguration: result.data.createAction.actionConfiguration,
        };

        setTableSource([...tableSource, newData]);
      } catch (error) {
        console.error("Mutation error:", error);
      }
    } else if (action.state === ModalStates.EDIT) {
      try {
        const result = await updateActionMutation({
          variables: {
            id: action.id,
            title: action.title,
            description: action.description,
            actionType: action.actionType,
            actionConfiguration: action.actionConfiguration,
          },
        });

        setTableSource((tableSource) =>
          tableSource.map((item) =>
            item.id === action.id
              ? {
                  key: result.data.updateAction.id,
                  id: result.data.updateAction.id,
                  title: result.data.updateAction.title,
                  description: result.data.updateAction.description,
                  actionType: result.data.updateAction.actionType,
                  actionConfiguration:
                    result.data.updateAction.actionConfiguration,
                }
              : item
          )
        );
      } catch (error) {
        console.error("Mutation error:", error);
      }
    }
    setModalState(ModalStates.OFF);
    setSelectedRow(undefined);
  };

  const SortableAccordionItem = ({ item }: { item: ActionTableDataType }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
    } = useSortable({ id: item.key });

    const style = {
      transform: CSS.Transform.toString(
        transform && { ...transform, scaleY: 1 }
      ),
      transition,
    };

    return (
      <Accordion.Item
        key={item.key}
        value={item.key}
        ref={setNodeRef}
        style={style}
        className="process-accordion-item"
      >
        <Accordion.Control>
          <Group>
            <div
              ref={setActivatorNodeRef}
              style={{ cursor: "move" }}
              {...listeners}
              {...attributes}
            >
              <IconGripVertical size={12} />
            </div>
            <div style={{ flex: 1 }}>{item.title}</div>
            <Menu shadow="md" width={200} position="bottom-end">
              <Menu.Target>
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="gray"
                  onClick={(e) => e.stopPropagation()}
                >
                  <IconDotsVertical size={12} />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown onClick={(e) => e.stopPropagation()}>
                <Menu.Item
                  color="red"
                  leftSection={<IconTrash size={14} />}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteActionClick(item);
                  }}
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Accordion.Control>
        <Accordion.Panel>
          <Stack w="100%">
            <ActionForm
              onSave={handleSaveAction}
              onCancel={handleCancel}
              initialState="EDIT"
              selectedRow={item}
            />
          </Stack>
        </Accordion.Panel>
      </Accordion.Item>
    );
  };

  return (
    <div>
      <Group justify="space-between" py={8}>
        <div style={{ fontSize: "14px", fontWeight: 500 }}>{title}</div>
        <ActionIcon variant="outline" size="sm" onClick={handleAddAction}>
          <IconPlus size={12} />
        </ActionIcon>
      </Group>

      <Accordion>
        {tableSource.map((item) => (
          <SortableAccordionItem key={item.key} item={item} />
        ))}
      </Accordion>

      <Modal
        opened={modalState !== ModalStates.OFF}
        onClose={handleCancel}
        title={modalState === ModalStates.ADD ? "Add Action" : "Edit Action"}
        size="lg"
      >
        <ActionForm
          onSave={handleSaveAction}
          onCancel={handleCancel}
          initialState={modalState === ModalStates.ADD ? "ADD" : "EDIT"}
          selectedRow={selectedRow}
        />
      </Modal>
    </div>
  );
};
