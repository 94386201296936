import { gql } from "@apollo/client";

export const PROCESS_DETAILS = gql`
  fragment ProcessDetails on Process {
    id
    title
    spaceId
    spaceTitle
    priority
    description
    deadline_units
    deadline_uom
    procedures {
      id
      title
      description
      priority
    }
    triggerSchedule {
      id
      frequency
      dayOfWeek
      dayOfMonth
      monthOfYear
      timeOfDay
    }
  }
`;

export const CYCLE_DETAILS = gql`
  fragment CycleDetails on ProcessCycle {
    id
    title
    status
    priority
    deadline
    description
    process {
      id
      title
      priority
      spaceId
      spaceTitle
      triggerSchedule {
        id
        frequency
      }
    }
    procedureCycles {
      id
      status
      description
      priority
      procedure {
        id
        title
        description
      }
      stepCycles {
        id
        status
        description
        step {
          id
          title
          description
        }
        actionCycles {
          id
          status
          description
          action {
            id
            title
            description
            actionType
            actionConfiguration
          }
        }
      }
    }
  }
`;
