import { useState } from "react";
import {
  TextInput,
  Button,
  Select,
  Stack,
  Box,
  ActionIcon,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { WorkPriority } from "../../gql/graphql";
import { Description } from "../../components/Description";
import { TimePeriod } from "../../components/TimePeriod";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_PROCESS_WIZARD,
  GET_PROCESS_CONFIG_BY_ENTITY,
} from "../../shared/queries";
import {
  CREATE_PROCESS_CONFIG,
  UPDATE_PROCESS_CONFIG,
} from "../../shared/mutations";
import { ProceduresTable } from "../tables/ProceduresTable";
import { IconCheck, IconX, IconRobot, IconSettings } from "@tabler/icons-react";
import { ProcessEntityConfigForm } from "./ProcessEntityConfigForm";

const { Option } = Select;
interface ProcessFormProps {
  onSave: (values: any) => void;
  onCancel: () => void;
  initialState: "ADD" | "EDIT";
  selectedRow: any;
}

export const ProcessForm: React.FC<ProcessFormProps> = ({
  onSave,
  onCancel,
  initialState,
  selectedRow,
}) => {
  const [
    getProcessWizard,
    { data: processWizardData, loading: processWizardLoading },
  ] = useLazyQuery(GET_PROCESS_WIZARD);
  const [isEditing] = useState(initialState === "EDIT");
  const [descriptionKey, setDescriptionKey] = useState(0);
  const [showConfigForm, setShowConfigForm] = useState(false);
  const [configFormState, setConfigFormState] = useState<"ADD" | "EDIT">("ADD");
  const [configData, setConfigData] = useState<any>(null);

  const form = useForm(
    initialState === "EDIT"
      ? {
          initialValues: {
            id: selectedRow.id,
            title: selectedRow.title,
            priority: selectedRow.priority,
            description: selectedRow.description,
            deadline_uom: selectedRow.deadline_uom,
            deadline_units: selectedRow.deadline_units,
            state: "EDIT",
          },
        }
      : {
          initialValues: {
            id: "",
            title: "",
            priority: WorkPriority.Medium,
            description: "",
            deadline_uom: "",
            deadline_units: "",
            state: "ADD",
          },
        }
  );

  const [getProcessConfig, { loading: configLoading }] = useLazyQuery(
    GET_PROCESS_CONFIG_BY_ENTITY,
    {
      onCompleted: (data) => {
        if (data && data.processConfigByEntity) {
          setConfigData(data.processConfigByEntity);
          setConfigFormState("EDIT");
        } else {
          setConfigData(null);
          setConfigFormState("ADD");
        }
        setShowConfigForm(true);
      },
      onError: (error) => {
        console.error("Error fetching process config:", error);
        setConfigData(null);
        setConfigFormState("ADD");
        setShowConfigForm(true);
      },
    }
  );

  const [createProcessConfig] = useMutation(CREATE_PROCESS_CONFIG);
  const [updateProcessConfig] = useMutation(UPDATE_PROCESS_CONFIG);

  const handleSave = async (withAI: boolean) => {
    try {
      const validation = form.validate();
      if (!validation.hasErrors) {
        if (withAI) {
          const wizard = await getProcessWizard({
            variables: {
              title: form.values.title,
              description: form.values.description,
            },
          });
          if (!processWizardLoading && wizard.data.getProcessWizardJson) {
            const json = wizard.data.getProcessWizardJson.json;
            form.setFieldValue("json", json);
            // Use the json data as needed
          }
        }
        onSave(form.values);
        onCancel();
      }
    } catch (error) {
      console.error("Validation error:", error);
    }
  };

  const handleCancel = () => {
    form.reset();
    // form.setFieldValue("state", "ADD");
    setDescriptionKey((k) => k + 1);
    onCancel();
  };

  const handleDeadlineChange = (
    units: number | undefined,
    uom: string | undefined
  ) => {
    form.setFieldValue("deadline_uom", uom || "");
    form.setFieldValue("deadline_units", units || "");
  };

  const toggleConfigForm = () => {
    if (!showConfigForm && selectedRow?.id) {
      getProcessConfig({
        variables: {
          entityId: selectedRow.id,
          entityType: "PROCESS",
        },
      });
    } else {
      setShowConfigForm(!showConfigForm);
    }
  };

  const handleConfigSave = async (values: any) => {
    try {
      if (configFormState === "ADD") {
        await createProcessConfig({
          variables: {
            entityId: selectedRow.id,
            entityType: "PROCESS",
            inputSource: values.inputSource,
            inputSourceConfiguration: values.inputSourceConfiguration,
            inputDescription: values.inputDescription,
            inputSample: values.inputSample,
            inputValidationType: values.inputValidationType,
            inputValidation: values.inputValidation,
            outputDescription: values.outputDescription,
            outputSample: values.outputSample,
            outputValidationType: values.outputValidationType,
            outputValidation: values.outputValidation,
          },
        });
      } else {
        await updateProcessConfig({
          variables: {
            id: values.id,
            inputSource: values.inputSource,
            inputSourceConfiguration: values.inputSourceConfiguration,
            inputDescription: values.inputDescription,
            inputSample: values.inputSample,
            inputValidationType: values.inputValidationType,
            inputValidation: values.inputValidation,
            outputDescription: values.outputDescription,
            outputSample: values.outputSample,
            outputValidationType: values.outputValidationType,
            outputValidation: values.outputValidation,
          },
        });
      }
    } catch (error) {
      console.error("Error saving process config:", error);
    }
  };

  console.log("selectedRow", selectedRow);

  return (
    <Stack gap={0}>
      <Box
        component="form"
        onSubmit={form.onSubmit(() => handleSave(false))}
        py={8}
      >
        <Stack gap={24}>
          {/* <input type="hidden" {...form.getInputProps("id")} />
          <input type="hidden" {...form.getInputProps("state")} />
          <input type="hidden" {...form.getInputProps("deadline_uom")} />
          <input type="hidden" {...form.getInputProps("deadline_units")} /> */}

          <TextInput
            label="Title"
            required
            {...form.getInputProps("title")}
            error={form.errors.title && "Please enter a title"}
          />

          <Description
            key={descriptionKey}
            markdown={selectedRow?.description ?? ""}
            onChange={(markdown) => form.setFieldValue("description", markdown)}
            showToolbar={form.isDirty()}
            placeholder="Describe the process purpose and details..."
          />

          <Select
            label="Default Priority"
            required
            defaultValue="Medium"
            {...form.getInputProps("priority")}
            data={[
              { value: "Low", label: "Low" },
              { value: "Medium", label: "Medium" },
              { value: "High", label: "High" },
              { value: "Critical", label: "Critical" },
            ]}
            error={form.errors.priority && "Please select a priority"}
          />

          <TimePeriod
            label="Default Deadline"
            units={form.values.deadline_units}
            uom={form.values.deadline_uom}
            onChange={handleDeadlineChange}
          />

          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Box style={{ display: "flex", gap: "8px" }}>
              {(initialState !== "EDIT" || form.isDirty()) && (
                <>
                  {isEditing !== true && (
                    <ActionIcon
                      variant="outline"
                      color="teal"
                      onClick={() => handleSave(true)}
                      disabled={form.isDirty() === false}
                      aria-label="Add with AI"
                    >
                      <IconRobot size="1.125rem" />
                    </ActionIcon>
                  )}

                  <ActionIcon
                    type="submit"
                    variant="filled"
                    color="blue"
                    disabled={form.isDirty() === false}
                    aria-label={isEditing ? "Save Changes" : "Add Process"}
                  >
                    <IconCheck size="1.125rem" />
                  </ActionIcon>

                  <ActionIcon
                    variant="outline"
                    color="red"
                    onClick={handleCancel}
                    aria-label={isEditing ? "Undo" : "Cancel"}
                  >
                    <IconX size="1.125rem" />
                  </ActionIcon>
                </>
              )}
            </Box>

            <ActionIcon
              variant="outline"
              color="gray"
              aria-label="Settings"
              onClick={toggleConfigForm}
            >
              <IconSettings size="1.125rem" />
            </ActionIcon>
          </Box>
        </Stack>
      </Box>

      {showConfigForm && (
        <Box mt={0}>
          <ProcessEntityConfigForm
            onSave={handleConfigSave}
            onCancel={() => setShowConfigForm(false)}
            initialState={configFormState}
            selectedRow={configData}
          />
        </Box>
      )}

      <Box>
        <ProceduresTable title="Procedures" processId={selectedRow.id} />
      </Box>
    </Stack>
  );
};

export default ProcessForm;
