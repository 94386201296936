import { Spoiler } from "@mantine/core";
import { RichTextEditor } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";

interface ShowDescriptionProps {
  description: string;
}

export function ShowDescription({ description }: ShowDescriptionProps) {
  const editor = useEditor({
    extensions: [StarterKit],
    content: description || "",
    editable: false,
  });

  if (!description) {
    return null;
  }

  return (
    <Spoiler maxHeight={70} showLabel="Show more" hideLabel="Hide">
      <RichTextEditor
        editor={editor}
        styles={{
          root: { border: "none" },
          content: { border: "none" },
        }}
      >
        <RichTextEditor.Content />
      </RichTextEditor>
    </Spoiler>
  );
}
