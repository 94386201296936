import { MultiSelect } from "@mantine/core";
import { useState, useEffect } from "react";

const monthsOfYearOptions = [
  { value: "JANUARY", label: "January" },
  { value: "FEBRUARY", label: "February" },
  { value: "MARCH", label: "March" },
  { value: "APRIL", label: "April" },
  { value: "MAY", label: "May" },
  { value: "JUNE", label: "June" },
  { value: "JULY", label: "July" },
  { value: "AUGUST", label: "August" },
  { value: "SEPTEMBER", label: "September" },
  { value: "OCTOBER", label: "October" },
  { value: "NOVEMBER", label: "November" },
  { value: "DECEMBER", label: "December" },
];

const MonthsOfYear: React.FC<{
  months: string[];
  onChange: (selectedMonths: string[]) => void;
}> = ({ months, onChange }) => {
  const [selectedMonths, setSelectedMonths] = useState<string[]>(months);

  // Update selectedMonths when the months prop changes
  useEffect(() => {
    setSelectedMonths(months);
  }, [months]);

  const handleChange = (checkedValues: string[]) => {
    setSelectedMonths(checkedValues);
    if (typeof onChange === "function") {
      // Sort months in calendar order
      const monthOrder = {
        JANUARY: 1,
        FEBRUARY: 2,
        MARCH: 3,
        APRIL: 4,
        MAY: 5,
        JUNE: 6,
        JULY: 7,
        AUGUST: 8,
        SEPTEMBER: 9,
        OCTOBER: 10,
        NOVEMBER: 11,
        DECEMBER: 12,
      };

      const sortedMonths = [...checkedValues].sort(
        (a, b) =>
          monthOrder[a as keyof typeof monthOrder] -
          monthOrder[b as keyof typeof monthOrder]
      );

      onChange(sortedMonths);
    }
  };

  return (
    <MultiSelect
      data={monthsOfYearOptions}
      value={selectedMonths}
      onChange={handleChange}
      styles={{ dropdown: { maxWidth: "200px" } }}
      searchable
      clearable
    />
  );
};

export default MonthsOfYear;
