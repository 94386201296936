import { useState, useEffect } from "react";
import {
  TextInput,
  Button,
  Select,
  Stack,
  Box,
  ActionIcon,
  Tabs,
  Group,
  Loader,
  Text,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconCheck, IconX, IconInputX, IconOutlet } from "@tabler/icons-react";
import { Description } from "../../components/Description";
import Ajv from "ajv";

// Define input source types
enum InputSourceType {
  NONE = "NONE",
  USER = "USER",
  EXTERNAL = "EXTERNAL",
  LOCAL = "LOCAL",
  SET_VALUE = "SET_VALUE",
}

// Schema definitions for different input sources
const getSchemaForInputSource = (inputSource: InputSourceType) => {
  switch (inputSource) {
    case InputSourceType.USER:
      return {
        type: "object",
        properties: {
          fieldsSchema: {
            type: "string",
            title: "Fields (JSON Schema)",
            description: "JSON Schema defining the fields for user input",
            maxLength: 100000,
          },
        },
        required: ["fieldsSchema"],
      };
    case InputSourceType.EXTERNAL:
      return {
        type: "object",
        properties: {
          serviceUrl: {
            type: "string",
            title: "Service URL",
            description: "URL of the external service",
            maxLength: 255,
          },
          headers: {
            type: "string",
            title: "Headers",
            description: "HTTP headers in JSON format",
            maxLength: 1000,
          },
          apiKey: {
            type: "string",
            title: "API Key",
            description: "API key for authentication (if required)",
            maxLength: 255,
          },
        },
        required: ["serviceUrl"],
      };
    case InputSourceType.LOCAL:
      return {
        type: "object",
        properties: {
          sourceEntityId: {
            type: "string",
            title: "Source Entity ID",
            description: "ID of the source process/procedure/step/action",
            maxLength: 255,
          },
          dataType: {
            type: "string",
            title: "Data Type",
            description: "Whether to use the source's input or output data",
            enum: ["INPUT", "OUTPUT"],
          },
          outputPath: {
            type: "string",
            title: "Data Path",
            description: "JSON path to extract data from source",
            maxLength: 255,
          },
        },
        required: ["sourceEntityId", "dataType"],
      };
    case InputSourceType.SET_VALUE:
      return {
        type: "object",
        properties: {
          value: {
            type: "string",
            title: "Value",
            description: "Fixed value to use as input",
            maxLength: 100000,
          },
        },
        required: ["value"],
      };
    default:
      return {
        type: "object",
        properties: {},
        required: [],
      };
  }
};

interface ProcessEntityConfigFormProps {
  onSave: (values: any) => void;
  onCancel: () => void;
  initialState: "ADD" | "EDIT";
  selectedRow: any;
}

export const ProcessEntityConfigForm: React.FC<
  ProcessEntityConfigFormProps
> = ({ onSave, onCancel, initialState, selectedRow }) => {
  const [isEditing] = useState(initialState === "EDIT");
  const [descriptionKey, setDescriptionKey] = useState(0);
  const [activeTab, setActiveTab] = useState<string | null>("input");
  const [inputSource, setInputSource] = useState<InputSourceType>(
    InputSourceType.NONE
  );
  const [inputSourceConfig, setInputSourceConfig] = useState<
    Record<string, string>
  >({});
  const [isLoading, setIsLoading] = useState(false);
  const [validationError, setValidationError] = useState<string | null>(null);

  const form = useForm({
    initialValues:
      initialState === "EDIT" && selectedRow
        ? {
            id: selectedRow.id,
            inputSource: selectedRow.inputSource || "NONE",
            inputSourceConfiguration:
              selectedRow.inputSourceConfiguration || "",
            inputDescription: selectedRow.inputDescription,
            inputSample: selectedRow.inputSample,
            inputValidationType: selectedRow.inputValidationType || "NONE",
            inputValidation: selectedRow.inputValidation,
            outputDescription: selectedRow.outputDescription || "",
            outputSample: selectedRow.outputSample || "",
            outputValidationType: selectedRow.outputValidationType || "NONE",
            outputValidation: selectedRow.outputValidation || "",
            state: "EDIT",
          }
        : {
            id: "",
            inputSource: "NONE",
            inputSourceConfiguration: "",
            inputDescription: "",
            inputSample: "",
            inputValidationType: "NONE",
            inputValidation: "",
            outputDescription: "",
            outputSample: "",
            outputValidationType: "NONE",
            outputValidation: "",
            state: "ADD",
          },
    validate: {
      inputValidationType: (value) =>
        !value ? "Validation type is required" : null,
    },
  });

  // Initialize input source configuration when component mounts or form values change
  useEffect(() => {
    setIsLoading(true);
    const sourceType = form.values.inputSource as InputSourceType;
    setInputSource(sourceType);

    try {
      // Parse the JSON configuration string into an object
      const config = form.values.inputSourceConfiguration
        ? JSON.parse(form.values.inputSourceConfiguration)
        : {};
      setInputSourceConfig(config);
    } catch (e) {
      console.error("Failed to parse input source configuration:", e);
      setInputSourceConfig({});
    }

    setIsLoading(false);
  }, [form.values.inputSource, form.values.inputSourceConfiguration]);

  // Validates the configuration against the schema
  const validateConfig = (
    config: Record<string, string>,
    schema: any
  ): boolean => {
    const ajv = new Ajv();
    const validate = ajv.compile(schema);
    const isValid = validate(config);

    if (!isValid) {
      const errors = validate.errors;
      if (errors && errors.length > 0) {
        // Format validation errors into a readable message
        const errorMessage = errors
          .map((err) => {
            const field =
              err.instancePath.replace("/", "") || err.params.missingProperty;
            return `${field}: ${err.message}`;
          })
          .join(", ");
        setValidationError(errorMessage);
        return false;
      }
    }

    setValidationError(null);
    return true;
  };

  // Handles input source selection changes
  const handleInputSourceChange = (value: string | null, option?: any) => {
    setIsLoading(true);
    const sourceType = value || InputSourceType.NONE;
    form.setFieldValue("inputSource", sourceType);
    form.setFieldValue("inputSourceConfiguration", "");
    setInputSource(sourceType as InputSourceType);
    setInputSourceConfig({});
    setValidationError(null);

    setTimeout(() => setIsLoading(false), 50);
  };

  // Handles changes to configuration fields
  const handleConfigChange = (field: string, value: string) => {
    const updatedConfig = {
      ...inputSourceConfig,
      [field]: value,
    };
    setInputSourceConfig(updatedConfig);
    form.setFieldValue(
      "inputSourceConfiguration",
      JSON.stringify(updatedConfig)
    );
    setValidationError(null);
  };

  // Dynamically renders configuration fields based on the selected input source
  const renderConfigFields = () => {
    if (inputSource === InputSourceType.NONE) return null;

    const schema = getSchemaForInputSource(inputSource);
    if (!schema) return null;

    const properties = schema.properties as Record<string, any>;
    const required = schema.required as string[];

    return Object.entries(properties).map(([field, fieldSchema]) => {
      // Use Textarea for long text fields
      const isLongText = fieldSchema.maxLength && fieldSchema.maxLength > 100;
      const isEnum = fieldSchema.enum !== undefined;
      const isSetValueField =
        inputSource === InputSourceType.SET_VALUE && field === "value";

      const commonProps = {
        label: fieldSchema.title || fieldSchema.description || field,
        value: inputSourceConfig[field] || "",
        onChange: (
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => handleConfigChange(field, e.target.value),
        required: required.includes(field),
        placeholder: `Enter ${fieldSchema.description || field}`,
        error: validationError?.includes(field)
          ? validationError
              .split(", ")
              .find((err) => err.startsWith(field))
              ?.split(": ")[1]
          : undefined,
      };

      if (isSetValueField) {
        return (
          <Box key={field}>
            <Box mb={8} style={{ fontSize: "14px", fontWeight: 500 }}>
              {fieldSchema.title || fieldSchema.description || field}
              {required.includes(field) && (
                <span style={{ color: "red" }}> *</span>
              )}
            </Box>
            <Description
              markdown={inputSourceConfig[field] || ""}
              onChange={(markdown) => handleConfigChange(field, markdown)}
              showToolbar={true}
              placeholder={`Enter ${fieldSchema.description || field}`}
            />
            {validationError?.includes(field) && (
              <Text color="red" size="sm">
                {
                  validationError
                    .split(", ")
                    .find((err) => err.startsWith(field))
                    ?.split(": ")[1]
                }
              </Text>
            )}
          </Box>
        );
      } else if (isEnum) {
        return (
          <Select
            key={field}
            {...commonProps}
            data={fieldSchema.enum.map((value: string) => ({
              value,
              label: value,
            }))}
            onChange={(value) => handleConfigChange(field, value || "")}
          />
        );
      } else if (isLongText) {
        return (
          <Textarea key={field} {...commonProps} minRows={3} maxRows={6} />
        );
      } else if (fieldSchema.type === "boolean") {
        return (
          <Select
            key={field}
            {...commonProps}
            data={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" },
            ]}
            onChange={(value) => handleConfigChange(field, value || "false")}
          />
        );
      } else {
        return <TextInput key={field} {...commonProps} />;
      }
    });
  };

  const handleSave = async () => {
    try {
      // Validate input source configuration if an input source is selected
      if (inputSource !== InputSourceType.NONE) {
        const schema = getSchemaForInputSource(inputSource);

        // Convert string values to appropriate types based on schema
        const typedConfig: Record<string, any> = {};
        const properties = schema.properties as Record<string, any>;

        Object.entries(inputSourceConfig).forEach(([key, value]) => {
          const propertySchema = properties[key];
          if (propertySchema) {
            if (propertySchema.type === "boolean") {
              typedConfig[key] = value === "true";
            } else {
              typedConfig[key] = value;
            }
          }
        });

        if (!validateConfig(typedConfig, schema)) {
          return;
        }

        // Update the form value with the validated configuration
        form.setFieldValue(
          "inputSourceConfiguration",
          JSON.stringify(typedConfig)
        );
      }

      const validation = form.validate();
      if (!validation.hasErrors) {
        onSave(form.values);
        onCancel();
      }
    } catch (error) {
      console.error("Validation error:", error);
    }
  };

  const handleSubmit = form.onSubmit(() => {
    handleSave();
  });

  const handleCancel = () => {
    form.reset();
    setDescriptionKey((k) => k + 1);
    onCancel();
  };

  return (
    <Box component="form" onSubmit={handleSubmit} pb={24}>
      <Tabs value={activeTab} onChange={setActiveTab}>
        <Tabs.List>
          <Tabs.Tab value="input" leftSection={<IconInputX size="0.8rem" />}>
            Input
          </Tabs.Tab>
          <Tabs.Tab value="output" leftSection={<IconOutlet size="0.8rem" />}>
            Output
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="input" pt={20}>
          <Stack gap={24}>
            <Select
              label="Input Source"
              value={form.values.inputSource}
              onChange={handleInputSourceChange}
              data={[
                { value: InputSourceType.NONE, label: "None" },
                { value: InputSourceType.USER, label: "User Input" },
                { value: InputSourceType.EXTERNAL, label: "External Source" },
                { value: InputSourceType.LOCAL, label: "Project Data" },
                { value: InputSourceType.SET_VALUE, label: "Set Value" },
              ]}
            />

            {isLoading ? (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px",
                }}
              >
                <Loader size="sm" />
              </Box>
            ) : (
              renderConfigFields()
            )}

            {validationError && (
              <Text color="red" size="sm">
                {validationError}
              </Text>
            )}

            <Box>
              <Box mb={8} style={{ fontSize: "14px", fontWeight: 500 }}>
                Input Description
              </Box>
              <Description
                key={descriptionKey}
                markdown={selectedRow?.inputDescription ?? ""}
                onChange={(markdown) =>
                  form.setFieldValue("inputDescription", markdown)
                }
                showToolbar={form.isDirty()}
                placeholder="Describe the expected input format and requirements..."
              />
            </Box>

            <Box>
              <Box mb={8} style={{ fontSize: "14px", fontWeight: 500 }}>
                Input Sample
              </Box>
              <Description
                markdown={form.values.inputSample || ""}
                onChange={(markdown) =>
                  form.setFieldValue("inputSample", markdown)
                }
                showToolbar={form.isDirty()}
                placeholder="Enter a sample of expected input data..."
              />
            </Box>

            <Select
              label="Input Validation Type"
              required
              {...form.getInputProps("inputValidationType")}
              data={[
                { value: "NONE", label: "None" },
                { value: "JSON_SCHEMA", label: "JSON Schema" },
                { value: "PROMPT", label: "Prompt" },
              ]}
              error={form.errors.inputValidationType}
            />

            <Box>
              <Box mb={8} style={{ fontSize: "14px", fontWeight: 500 }}>
                Input Validation
              </Box>
              <Description
                markdown={form.values.inputValidation || ""}
                onChange={(markdown) =>
                  form.setFieldValue("inputValidation", markdown)
                }
                showToolbar={form.isDirty()}
                placeholder={
                  form.values.inputValidationType === "JSON_SCHEMA"
                    ? "Enter a JSON schema for validating input..."
                    : form.values.inputValidationType === "PROMPT"
                    ? "Enter prompt-based validation instructions..."
                    : "No validation required"
                }
              />
            </Box>
          </Stack>
        </Tabs.Panel>

        <Tabs.Panel value="output" pt={20}>
          <Stack gap={24}>
            <Box>
              <Box mb={8} style={{ fontSize: "14px", fontWeight: 500 }}>
                Output Description
              </Box>
              <Description
                markdown={form.values.outputDescription || ""}
                onChange={(markdown) =>
                  form.setFieldValue("outputDescription", markdown)
                }
                showToolbar={form.isDirty()}
                placeholder="Describe the expected output format and requirements..."
              />
            </Box>

            <Box>
              <Box mb={8} style={{ fontSize: "14px", fontWeight: 500 }}>
                Output Sample
              </Box>
              <Description
                markdown={form.values.outputSample || ""}
                onChange={(markdown) =>
                  form.setFieldValue("outputSample", markdown)
                }
                showToolbar={form.isDirty()}
                placeholder="Enter a sample of expected output data..."
              />
            </Box>

            <Select
              label="Output Validation Type"
              {...form.getInputProps("outputValidationType")}
              data={[
                { value: "NONE", label: "None" },
                { value: "JSON_SCHEMA", label: "JSON Schema" },
                { value: "PROMPT", label: "Prompt" },
              ]}
            />

            <Box>
              <Box mb={8} style={{ fontSize: "14px", fontWeight: 500 }}>
                Output Validation
              </Box>
              <Description
                markdown={form.values.outputValidation || ""}
                onChange={(markdown) =>
                  form.setFieldValue("outputValidation", markdown)
                }
                showToolbar={form.isDirty()}
                placeholder={
                  form.values.outputValidationType === "JSON_SCHEMA"
                    ? "Enter a JSON schema for validating output..."
                    : form.values.outputValidationType === "PROMPT"
                    ? "Enter prompt-based validation instructions..."
                    : "No validation required"
                }
              />
            </Box>
          </Stack>
        </Tabs.Panel>
      </Tabs>

      {(initialState !== "EDIT" || form.isDirty()) && (
        <Box style={{ display: "flex", gap: "8px" }} mt={24}>
          <ActionIcon
            type="submit"
            variant="filled"
            color="blue"
            disabled={form.isDirty() === false}
            aria-label={isEditing ? "Save Changes" : "Add Configuration"}
          >
            <IconCheck size="1.125rem" />
          </ActionIcon>
          <ActionIcon
            variant="outline"
            color="red"
            onClick={handleCancel}
            aria-label={isEditing ? "Undo" : "Cancel"}
          >
            <IconX size="1.125rem" />
          </ActionIcon>
        </Box>
      )}
    </Box>
  );
};

export default ProcessEntityConfigForm;
