import React, { useEffect } from "react";
import { useState } from "react";
// import { Modal, Table, TableProps, Button, Card } from "antd";
// import { EditOutlined, DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import { StepForm } from "../forms/StepForm";
import { gql, useMutation, useQuery } from "@apollo/client";
import { CSS } from "@dnd-kit/utilities";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CREATE_STEP, UPDATE_STEP } from "../../shared/mutations";
import {
  Accordion,
  Group,
  Stack,
  Button,
  Modal,
  ActionIcon,
  Divider,
  Menu,
} from "@mantine/core";
import {
  IconGripVertical,
  IconEdit,
  IconTrash,
  IconPlus,
  IconDotsVertical,
} from "@tabler/icons-react";
import { ActionsTable } from "./ActionsTable";
import { openDeleteConfirmation } from "./Components/DeleteEntityConfirmation";

export interface StepTableDataType {
  key: string;
  id: string;
  title: string;
  description: string;
}

interface EntityTableProps {
  title: string;
  procedureId: string;
}

const GET_PROCEDURE_STEPS = gql`
  query GetProcessSteps($procedureId: ID!) {
    procedureById(id: $procedureId) {
      title
      steps {
        id
        title
        description
      }
    }
  }
`;

export const UPDATE_STEP_ORDER = gql`
  mutation UpdateStepOrder($ids: [ID!]!) {
    updateStepOrder(ids: $ids)
  }
`;

export const DELETE_STEP = gql`
  mutation deleteStep($id: ID!) {
    deleteStep(id: $id)
  }
`;

export function useCreateStepMutation() {
  return useMutation(CREATE_STEP);
}

export function useUpdateStepMutation() {
  return useMutation(UPDATE_STEP);
}

export function useUpdateStepOrderMutation() {
  return useMutation(UPDATE_STEP_ORDER);
}

export function useDeleteStepMutation() {
  return useMutation(DELETE_STEP);
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === "sort") {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <div
                ref={setActivatorNodeRef}
                style={{ touchAction: "none", cursor: "move" }}
                {...listeners}
              >
                <IconGripVertical size={12} />
              </div>
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

export const StepsTable: React.FC<EntityTableProps> = ({
  title,
  procedureId,
}) => {
  const ModalStates = {
    OFF: "OFF",
    ADD: "ADD",
    EDIT: "EDIT",
  };
  const [tableSource, setTableSource] = useState<StepTableDataType[]>([]);
  const [modalState, setModalState] = useState(ModalStates.OFF);
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const [selectedRow, setSelectedRow] = useState<StepTableDataType>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const [createStepMutation, { data: createStepData }] =
    useCreateStepMutation();
  const [updateStepMutation, { data: updateStepData }] =
    useUpdateStepMutation();
  const [updateStepOrderMutation, { data: updateStepOrderData }] =
    useUpdateStepOrderMutation();
  const [deleteStepMutation, { data: deleteStepData }] =
    useDeleteStepMutation();

  const { loading, error, data } = useQuery(GET_PROCEDURE_STEPS, {
    variables: { procedureId: procedureId },
  });

  useEffect(() => {
    if (!loading && !error) {
      setTableSource(
        data.procedureById.steps.map((step: any) => ({
          key: step.id,
          id: step.id,
          title: step.title,
          description: step.description,
        })) || []
      );
    }
  }, [data, loading, error]);

  const handleMouseEnter = (record: any) => {
    setHoveredRow(record.key);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const handleCancel = () => {
    setModalState(ModalStates.OFF);
  };

  const handleAddStep = () => {
    setModalState(ModalStates.ADD);
  };

  const handleEditStep = (entity: any) => {
    setSelectedRow(entity);
    setModalState(ModalStates.EDIT);
  };

  const handleDeleteStepClick = (entity: any) => {
    openDeleteConfirmation(entity.title, "Step", async () => {
      await handleDeleteStep(entity);
    });
  };

  const handleDeleteStep = async (step: any) => {
    try {
      const result = await deleteStepMutation({
        variables: {
          id: step.id,
        },
      });

      setTableSource(tableSource.filter((row) => row.key !== step.id));

      // Handle the result if needed
      console.log("Mutation result:", result);
      return true;
    } catch (error) {
      // Handle the error if the mutation fails
      console.error("Mutation error:", error);
      return false;
    }
  };

  const handleSaveStep = async (step: any) => {
    if (step.state === ModalStates.ADD) {
      try {
        const result = await createStepMutation({
          variables: {
            title: step.title,
            description: step.description,
            procedureId: procedureId,
          },
        });

        const newData: StepTableDataType = {
          key: result.data.createStep.id,
          id: result.data.createStep.id,
          title: result.data.createStep.title,
          description: result.data.createStep.description,
        };

        setTableSource([...tableSource, newData]);

        // Handle the result if needed
        console.log("Mutation result:", result);
      } catch (error) {
        // Handle the error if the mutation fails
        console.error("Mutation error:", error);
      }
    } else if (step.state === ModalStates.EDIT) {
      try {
        const result = await updateStepMutation({
          variables: {
            id: step.id, // Provide the step ID to identify which step to update
            title: step.title,
            description: step.description,
          },
        });

        setTableSource((tableSource) =>
          tableSource.map((item) =>
            item.id === step.id
              ? {
                  key: result.data.updateStep.id,
                  id: result.data.updateStep.id,
                  title: result.data.updateStep.title,
                  description: result.data.updateStep.description,
                }
              : item
          )
        );
        // Handle the result if needed
        console.log("Mutation result:", result);
      } catch (error) {
        // Handle the error if the mutation fails
        console.error("Mutation error:", error);
      }
    }
    setModalState(ModalStates.OFF);
  };

  const handleOrderSteps = async (newTableSource: any) => {
    try {
      const result = await updateStepOrderMutation({
        variables: {
          ids: newTableSource.map((item: any) => item.id),
        },
      });
      // Handle the result if needed
      console.log("Mutation result:", result);
      return true;
    } catch (error) {
      // Handle the error if the mutation fails
      console.error("Mutation error:", error);
      return false;
    }
  };

  const SortableAccordionItem = ({ item }: { item: StepTableDataType }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
    } = useSortable({ id: item.key });

    const style = {
      transform: CSS.Transform.toString(
        transform && { ...transform, scaleY: 1 }
      ),
      transition,
    };

    return (
      <Accordion.Item
        key={item.key}
        value={item.key}
        ref={setNodeRef}
        style={style}
        className="process-accordion-item"
      >
        <Accordion.Control>
          <Group>
            <div
              ref={setActivatorNodeRef}
              style={{ cursor: "move" }}
              {...listeners}
              {...attributes}
            >
              <IconGripVertical size={12} />
            </div>
            <div style={{ flex: 1 }}>{item.title}</div>
            <Menu shadow="md" width={200} position="bottom-end">
              <Menu.Target>
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="gray"
                  onClick={(e) => e.stopPropagation()}
                >
                  <IconDotsVertical size={12} />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown onClick={(e) => e.stopPropagation()}>
                <Menu.Item
                  color="red"
                  leftSection={<IconTrash size={14} />}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteStepClick(item);
                  }}
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Accordion.Control>
        <Accordion.Panel>
          <Stack w="100%">
            <StepForm
              onSave={handleSaveStep}
              onCancel={handleCancel}
              initialState="EDIT"
              selectedRow={item}
            />
            <Divider style={{ marginBottom: "8px", marginTop: "4px" }} />
            <ActionsTable title="Actions" stepId={item.id} />
          </Stack>
        </Accordion.Panel>
      </Accordion.Item>
    );
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const newTableSource = () => {
        const activeIndex = tableSource.findIndex((i) => i.key === active.id);
        const overIndex = tableSource.findIndex((i) => i.key === over?.id);
        return arrayMove(tableSource, activeIndex, overIndex);
      };
      setTableSource(newTableSource());
      handleOrderSteps(newTableSource());
    }
  };

  return (
    <div>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={tableSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Group justify="space-between" py={8}>
            <div style={{ fontSize: "14px", fontWeight: 500 }}>{title}</div>
            <ActionIcon variant="outline" size="sm" onClick={handleAddStep}>
              <IconPlus size={12} />
            </ActionIcon>
          </Group>

          <Accordion>
            {tableSource.map((item) => (
              <SortableAccordionItem key={item.key} item={item} />
            ))}
          </Accordion>
        </SortableContext>
      </DndContext>
      <Modal
        zIndex={10000}
        title={modalState === ModalStates.ADD ? "Add Step" : "Edit Step"}
        opened={modalState !== ModalStates.OFF}
        onClose={handleCancel}
      >
        <StepForm
          onSave={handleSaveStep}
          onCancel={handleCancel}
          initialState={modalState === ModalStates.ADD ? "ADD" : "EDIT"}
          selectedRow={selectedRow}
        />
      </Modal>
    </div>
  );
};
