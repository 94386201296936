import { RichTextEditor } from "@mantine/tiptap";
// import {
//   MDXEditor,
//   listsPlugin,
//   toolbarPlugin,
//   UndoRedo,
//   ListsToggle,
//   BoldItalicUnderlineToggles,
//   MDXEditorMethods,
// } from "@mdxeditor/editor";
// import "@mdxeditor/editor/style.css";
import Placeholder from "@tiptap/extension-placeholder";
import { useEditor } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";
import { Table } from "@tiptap/extension-table";
import { TableHeader } from "@tiptap/extension-table-header";
import { TableRow } from "@tiptap/extension-table-row";
import { TableCell } from "@tiptap/extension-table-cell";
import { useEffect, useState } from "react";

interface DescriptionProps {
  markdown: string;
  onChange: (markdown: string) => void;
  showToolbar?: boolean;
  placeholder?: string;
}

// Add the Description component
export const Description: React.FC<DescriptionProps> = ({
  markdown,
  onChange,
  showToolbar = true,
  placeholder,
}) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (isActive) {
      // Add a listener to detect clicks outside the editor
      const handleClickOutside = () => {
        setIsActive(false);
      };

      document.addEventListener("click", handleClickOutside);

      // Clean up
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }
  }, [isActive]);

  const handleClick = () => {
    setIsActive(true);
  };

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder,
      }),
      Table,
      TableHeader,
      TableRow,
      TableCell,
    ],
    content: markdown,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    },
  });

  return (
    <RichTextEditor editor={editor} onClick={handleClick}>
      <RichTextEditor.Content />
    </RichTextEditor>
  );
};
