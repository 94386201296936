import { MultiSelect } from "@mantine/core";
import { useState, useEffect } from "react";

const daysOfMonthOptions: { value: string; label: string }[] = [];
for (let i = 1; i <= 31; i++) {
  daysOfMonthOptions.push({
    value: i.toString(),
    label: i.toString(),
  });
}

const DaysOfMonth: React.FC<{
  days: number[];
  onChange: (checkedValues: number[]) => void;
}> = ({ days, onChange }) => {
  // Convert days to strings initially to match MultiSelect's expected format
  const [selectedDays, setSelectedDays] = useState<string[]>(days.map(String));

  // Update selectedDays when the days prop changes
  useEffect(() => {
    setSelectedDays(days.map(String));
  }, [days]);

  const handleChange = (checkedValues: string[]) => {
    setSelectedDays(checkedValues);
    // Convert strings to numbers for the parent component
    if (typeof onChange === "function") {
      const numericValues = checkedValues.map(Number).sort((a, b) => a - b);
      onChange(numericValues);
    }
  };

  return (
    <MultiSelect
      data={daysOfMonthOptions}
      value={selectedDays}
      onChange={handleChange}
      styles={{ dropdown: { maxWidth: "200px" } }}
      searchable
      clearable
    />
  );
};

export default DaysOfMonth;
