import { gql } from "graphql-tag";

export const GET_PROCESS_WIZARD = gql`
  query getProcessWizard($title: String!, $description: String!) {
    getProcessWizardJson(title: $title, description: $description) {
      json
    }
  }
`;

export const GET_PROCESS_CONFIG_BY_ID = gql`
  query GetProcessConfigById($id: ID!) {
    processConfigById(id: $id) {
      id
      inputSource
      inputSourceConfiguration
      inputDescription
      inputSample
      inputValidationType
      inputValidation
      outputDescription
      outputSample
      outputValidationType
      outputValidation
    }
  }
`;

export const GET_PROCESS_CONFIG_BY_ENTITY = gql`
  query GetProcessConfigByEntity($entityId: ID!, $entityType: ItemType!) {
    processConfigByEntity(entityId: $entityId, entityType: $entityType) {
      id
      entityId
      entityType
      inputSource
      inputSourceConfiguration
      inputDescription
      inputSample
      inputValidationType
      inputValidation
      outputDescription
      outputSample
      outputValidationType
      outputValidation
    }
  }
`;
