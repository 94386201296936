import React, { useEffect } from "react";
import { useState } from "react";
// import { Modal, Table, TableProps, Button, Card, Space } from "antd";
import { CSS } from "@dnd-kit/utilities";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { StepsTable } from "./StepsTable";
import { CREATE_PROCEDURE, UPDATE_PROCEDURE } from "../../shared/mutations";
import Markdown from "react-markdown";
import {
  Accordion,
  Group,
  Button,
  Card,
  Modal,
  Stack,
  ActionIcon,
  Divider,
  Menu,
  Text,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconGripVertical,
  IconEdit,
  IconPlus,
  IconDotsVertical,
  IconTrash,
} from "@tabler/icons-react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { ProcedureForm } from "../forms/ProcedureForm";
import { PROCESS_DETAILS } from "../../shared/fragments";
import { Procedure } from "../../gql/graphql";
import { DELETE_PROCEDURE } from "../../shared/mutations";
import { openDeleteConfirmation } from "./Components/DeleteEntityConfirmation";

export interface ProcedureTableDataType {
  key: string;
  id: string;
  title: string;
  description: string;
  priority: string;
}

interface EntityTableProps {
  title: string;
  processId: string;
}

export const UPDATE_PROCEDURE_ORDER = gql`
  mutation UpdateProcedureOrder($ids: [ID!]!) {
    updateProcedureOrder(ids: $ids)
  }
`;

// Define the GraphQL query
const GET_PROCESS_INFO = gql`
  ${PROCESS_DETAILS}
  query GetProcessInfo($processId: ID!) {
    processById(id: $processId) {
      ...ProcessDetails
    }
  }
`;

export function useCreateProcedureMutation() {
  return useMutation(CREATE_PROCEDURE);
}

export function useUpdateProcedureMutation() {
  return useMutation(UPDATE_PROCEDURE);
}

export function useUpdateProcedureOrderMutation() {
  return useMutation(UPDATE_PROCEDURE_ORDER);
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

export const ProceduresTable: React.FC<EntityTableProps> = ({
  title,
  processId,
}) => {
  const [recordSource, setRecordSource] = useState<ProcedureTableDataType[]>(
    []
  );
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [createProcedureMutation] = useCreateProcedureMutation();
  const [updateProcedureMutation] = useUpdateProcedureMutation();
  const [updateProcedureOrderMutation] = useUpdateProcedureOrderMutation();
  const [deleteProcedureMutation] = useMutation(DELETE_PROCEDURE);

  const { loading, error, data, refetch } = useQuery(GET_PROCESS_INFO, {
    variables: { processId },
  });

  useEffect(() => {
    if (data) {
      const dataSource = data.processById.procedures.map(
        (procedure: Procedure) => ({
          key: procedure.id,
          id: procedure.id,
          title: procedure.title,
          priority: procedure.priority,
          description: procedure.description,
        })
      );
      setRecordSource(dataSource);
    }
  }, [data]);

  const handleMouseEnter = (record: any) => {
    setHoveredItem(record.key);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const handleCancel = () => {
    modals.closeAll();
  };

  const handleAddProcedure = () => {
    modals.open({
      title: "Add Procedure",
      children: (
        <ProcedureForm
          onSave={(values) => {
            handleSaveProcedure(values);
            modals.closeAll();
          }}
          onCancel={() => modals.closeAll()}
          initialState="ADD"
          selectedRow={null}
        />
      ),
    });
  };

  const handleEditProcedure = (entity: any) => {
    modals.open({
      title: "Edit Procedure",
      children: (
        <ProcedureForm
          onSave={(values) => {
            handleSaveProcedure(values);
            modals.closeAll();
          }}
          onCancel={() => modals.closeAll()}
          initialState="EDIT"
          selectedRow={entity}
        />
      ),
    });
  };

  const openDeleteModal = (item: ProcedureTableDataType) => {
    openDeleteConfirmation(item.title, "Procedure", async () => {
      await handleDeleteProcedure(item);
    });
  };

  const handleDeleteProcedure = async (
    procedureToDelete: ProcedureTableDataType
  ) => {
    try {
      await deleteProcedureMutation({
        variables: {
          id: procedureToDelete.id,
        },
      });

      setRecordSource((current) =>
        current.filter((item) => item.id !== procedureToDelete.id)
      );
    } catch (error) {
      console.error("Error deleting procedure:", error);
    }
  };

  const SortableAccordionItem = ({
    item,
  }: {
    item: ProcedureTableDataType;
  }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
    } = useSortable({ id: item.key });

    const style = {
      transform: CSS.Transform.toString(
        transform && { ...transform, scaleY: 1 }
      ),
      transition,
    };

    return (
      <Accordion.Item
        key={item.key}
        value={item.key}
        ref={setNodeRef}
        style={style}
        className="process-accordion-item"
      >
        <Accordion.Control>
          <Group>
            <div
              ref={setActivatorNodeRef}
              style={{ cursor: "move" }}
              {...listeners}
              {...attributes}
            >
              <IconGripVertical size={12} />
            </div>
            <div style={{ flex: 1 }}>{item.title}</div>
            <Menu shadow="md" width={200} position="bottom-end">
              <Menu.Target>
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="gray"
                  onClick={(e) => e.stopPropagation()}
                >
                  <IconDotsVertical size={12} />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown onClick={(e) => e.stopPropagation()}>
                <Menu.Item
                  color="red"
                  leftSection={<IconTrash size={14} />}
                  onClick={(e) => {
                    e.stopPropagation();
                    openDeleteModal(item);
                  }}
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Accordion.Control>
        <Accordion.Panel>
          <div style={{ display: "flex", flexDirection: "column", gap: "0" }}>
            <div>
              <ProcedureForm
                onSave={handleSaveProcedure}
                onCancel={handleCancel}
                initialState="EDIT"
                selectedRow={item}
              />
            </div>
            <Divider style={{ marginBottom: "8px", marginTop: "4px" }} />
            <StepsTable title="Steps" procedureId={item.id} />
          </div>
        </Accordion.Panel>
      </Accordion.Item>
    );
  };

  const handleSaveProcedure = async (procedure: any) => {
    if (procedure.state === "ADD") {
      try {
        const result = await createProcedureMutation({
          variables: {
            title: procedure.title,
            description: procedure.description,
            processId: processId,
            priority: procedure.priority,
          },
        });

        const newData: ProcedureTableDataType = {
          key: result.data.createProcedure.id,
          id: result.data.createProcedure.id,
          title: result.data.createProcedure.title,
          priority: result.data.createProcedure.priority,
          description: result.data.createProcedure.description,
        };

        setRecordSource([...recordSource, newData]);

        // Handle the result if needed
        console.log("Mutation result:", result);
      } catch (error) {
        // Handle the error if the mutation fails
        console.error("Mutation error:", error);
      }
    } else if (procedure.state === "EDIT") {
      try {
        const result = await updateProcedureMutation({
          variables: {
            id: procedure.id, // Provide the procedure ID to identify which procedure to update
            title: procedure.title,
            description: procedure.description,
            priority: procedure.priority,
          },
        });

        setRecordSource((recordSource) =>
          recordSource.map((item) =>
            item.id === procedure.id
              ? {
                  key: result.data.updateProcedure.id,
                  id: result.data.updateProcedure.id,
                  title: result.data.updateProcedure.title,
                  priority: result.data.updateProcedure.priority,
                  description: result.data.updateProcedure.description,
                }
              : item
          )
        );
        // Handle the result if needed
        console.log("Mutation result:", result);
      } catch (error) {
        // Handle the error if the mutation fails
        console.error("Mutation error:", error);
      }
    }
    modals.closeAll();
  };

  const handleOrderProcedures = async (newTableSource: any) => {
    try {
      const result = await updateProcedureOrderMutation({
        variables: {
          ids: newTableSource.map((item: any) => item.id),
        },
      });
      // Handle the result if needed
      console.log("Mutation result:", result);
      return true;
    } catch (error) {
      // Handle the error if the mutation fails
      console.error("Mutation error:", error);
      return false;
    }
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const newTableSource = () => {
        const activeIndex = recordSource.findIndex((i) => i.key === active.id);
        const overIndex = recordSource.findIndex((i) => i.key === over?.id);
        return arrayMove(recordSource, activeIndex, overIndex);
      };
      setRecordSource(newTableSource());
      handleOrderProcedures(newTableSource());
    }
  };

  return (
    <>
      <div style={{ backgroundColor: "#FFFFFF" }}>
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            items={recordSource.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Group justify="space-between" py={8}>
              <div style={{ fontSize: "14px", fontWeight: 500 }}>{title}</div>
              <ActionIcon
                variant="outline"
                size="sm"
                onClick={handleAddProcedure}
              >
                <IconPlus size={12} />
              </ActionIcon>
            </Group>

            <Accordion>
              {recordSource.map((item) => (
                <SortableAccordionItem key={item.key} item={item} />
              ))}
            </Accordion>
          </SortableContext>
        </DndContext>
      </div>
    </>
  );
};
