import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import { ProceduresTable } from "../tables/ProceduresTable";
import {
  Button,
  Card,
  Group,
  Modal,
  Stack,
  Tabs,
  Text,
  Grid,
  Title,
  Box,
} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { ProcessContext } from "../../contexts/ProcessContext";
import { SpaceContext } from "../../contexts/SpaceContext";
import TriggerScheduleForm from "../forms/TriggerScheduleForm";
import {
  CREATE_TRIGGER_SCHEDULE,
  UPDATE_TRIGGER_SCHEDULE,
  DELETE_TRIGGER_SCHEDULE,
} from "../../shared/mutations";
import ProcessForm from "../forms/ProcessForm";
import { updateProcess } from "../operations/process";
import ProjectTable from "../tables/ProjectTable";
import { PROCESS_DETAILS } from "../../shared/fragments";
import { RichTextEditor } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";

// Define the GraphQL query
const GET_PROCESS_INFO = gql`
  ${PROCESS_DETAILS}
  query GetProcessInfo($processId: ID!) {
    processById(id: $processId) {
      ...ProcessDetails
    }
  }
`;

function useCreateTriggerSchedule() {
  return useMutation(CREATE_TRIGGER_SCHEDULE);
}

function useUpdateTriggerSchedule() {
  return useMutation(UPDATE_TRIGGER_SCHEDULE);
}

function useDeleteTriggerSchedule() {
  return useMutation(DELETE_TRIGGER_SCHEDULE);
}

const ModalStates = {
  OFF: "OFF",
  ADD: "ADD",
  EDIT: "EDIT",
};

const env = process.env;

// Custom descriptions component using Mantine
function DescriptionItem({
  label,
  children,
}: {
  label?: string;
  children: React.ReactNode;
}) {
  const editor = useEditor({
    extensions: [StarterKit],
    content: typeof children === "string" ? children : "",
    editable: false,
  });

  return (
    <Grid gutter="xs">
      {label && (
        <Grid.Col span={{ base: 12, sm: 4 }}>
          <Text fw={500} c="dimmed">
            {label}
          </Text>
        </Grid.Col>
      )}
      <Grid.Col span={{ base: 12, sm: label ? 8 : 12 }}>
        {typeof children === "string" ? (
          <Box style={{ fontSize: "14px" }}>
            <RichTextEditor
              editor={editor}
              styles={{
                root: { border: "none" },
                content: { border: "none", padding: 0 },
              }}
            >
              <RichTextEditor.Content />
            </RichTextEditor>
          </Box>
        ) : (
          children
        )}
      </Grid.Col>
    </Grid>
  );
}

function Descriptions({
  title,
  items,
  bordered = false,
  size = "md",
}: {
  title?: string;
  items: { label?: string; children: React.ReactNode }[];
  bordered?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
}) {
  return (
    <Stack
      gap={size}
      p={bordered ? "xs" : 0}
      style={
        bordered
          ? {
              border: "1px solid var(--mantine-color-gray-3)",
              borderRadius: "var(--mantine-radius-md)",
            }
          : undefined
      }
    >
      {title && (
        <Text fw={700} size="lg">
          {title}
        </Text>
      )}
      {items.map((item, index) => (
        <DescriptionItem key={index} label={item.label}>
          {item.children}
        </DescriptionItem>
      ))}
    </Stack>
  );
}

export function ProcessDetails() {
  const { processId } = useParams(); // Get the processId from the route parameters
  const [processModalState, setProcessModalState] = useState(ModalStates.OFF);
  const [triggerModalState, setTriggerModalState] = useState(ModalStates.OFF);
  const [createTriggerSchedule, { data: createTriggerScheduleData }] =
    useCreateTriggerSchedule();
  const [updateTriggerSchedule, { data: updateTriggerScheduleData }] =
    useUpdateTriggerSchedule();
  const [deleteTriggerSchedule, { data: deleteTriggerScheduleData }] =
    useDeleteTriggerSchedule();
  // Use the useQuery hook to fetch data
  const { loading, error, data, refetch } = useQuery(GET_PROCESS_INFO, {
    variables: { processId },
  });
  const { space, setSpace } = useContext(SpaceContext);
  const { process, setProcess } = useContext(ProcessContext);

  useEffect(() => {
    if (data) {
      setSpace({
        cSpaceId: data.processById.spaceId,
        cSpaceName: data.processById.spaceTitle,
      });
      setProcess({
        cProcessId: processId ? processId : "",
        cProcessName: data?.processById.title,
      });
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) {
    if (
      error.networkError &&
      "statusCode" in error.networkError &&
      error.networkError.statusCode === 401
    ) {
      window.location.reload();
      return null;
    }
    return <p>Error: {error.message}</p>;
  }

  const handleCreateTriggerSchedule = () => {
    setTriggerModalState(ModalStates.ADD);
  };
  const handleCancel = () => {
    setTriggerModalState(ModalStates.OFF);
    setProcessModalState(ModalStates.OFF);
  };

  const handleSaveProcess = async (process: any) => {
    try {
      const result = await updateProcess(process);
      // Handle the result if needed
      console.log("Mutation result:", result);

      // Wait for refetch to complete before closing modal
      await refetch();
      setProcessModalState(ModalStates.OFF);
    } catch (error) {
      // Handle the error if the mutation fails
      console.error("Mutation error:", error);
    }
  };

  const handleSaveTriggerSchedule = async (triggerSchedule: any) => {
    console.log("TriggerSchedule:", triggerSchedule);
    if (triggerModalState === ModalStates.ADD) {
      try {
        const result = await createTriggerSchedule({
          variables: {
            frequency: triggerSchedule.frequency,
            dayOfWeek: triggerSchedule.dayOfWeek,
            dayOfMonth: triggerSchedule.dayOfMonth,
            monthOfYear: triggerSchedule.monthOfYear,
            timeOfDay: triggerSchedule.timeOfDay,
            processId: processId,
          },
        });

        // Correct the path to access data
        if (result.data && result.data.createTriggerSchedule) {
          console.log(
            "Created trigger schedule:",
            result.data.createTriggerSchedule
          );
        }

        // Handle the result if needed
        console.log("Mutation result:", result);
      } catch (error) {
        // Handle the error if the mutation fails
        console.error("Mutation error:", error);
      }
    } else if (triggerModalState === ModalStates.EDIT) {
      try {
        const result = await updateTriggerSchedule({
          variables: {
            id: triggerSchedule.triggerId,
            frequency: triggerSchedule.frequency,
            dayOfWeek: triggerSchedule.dayOfWeek,
            dayOfMonth: triggerSchedule.dayOfMonth,
            monthOfYear: triggerSchedule.monthOfYear,
            timeOfDay: triggerSchedule.timeOfDay,
          },
        });

        // Handle the result if needed
        console.log("Mutation result:", result);
      } catch (error) {
        // Handle the error if the mutation fails
        console.error("Mutation error:", error);
      }
    }

    // Add a small delay before refetching to ensure the server has updated
    setTimeout(() => {
      refetch();
    }, 300); // 300ms delay should be sufficient in most cases

    setTriggerModalState(ModalStates.OFF);
  };

  async function handleDeleteScheule() {
    // Add null check to prevent error if there's no trigger schedule
    if (data && data.processById && data.processById.triggerSchedule) {
      try {
        const result = await deleteTriggerSchedule({
          variables: {
            id: data.processById.triggerSchedule.id,
          },
        });

        // Add a small delay before refetching to ensure the server has updated
        setTimeout(() => {
          refetch();
        }, 300); // 300ms delay should be sufficient in most cases
      } catch (error) {
        console.error("Error deleting trigger schedule:", error);
      }
    } else {
      console.error("Cannot delete: No trigger schedule exists");
    }
  }

  // Replace the tab definitions with Mantine style
  const tabs = [
    {
      value: "cycles",
      content: (
        <ProjectTable id={processId ? processId : ""} cyclesBy="PROCESS" />
      ),
    },
    {
      value: "procedures",
      content: (
        <div>
          <ProceduresTable
            title="Procedures"
            processId={processId ? processId : ""}
          />
        </div>
      ),
    },
  ];

  // Display the process details here
  return (
    <div>
      <Card shadow="sm" padding="lg" radius="md" withBorder>
        <Card.Section p="md" withBorder>
          <Group justify="space-between" align="center">
            <Title size="md">{data.processById.title}</Title>
            <Button
              variant="outline"
              onClick={() => setProcessModalState(ModalStates.EDIT)}
            >
              Edit
            </Button>
          </Group>
        </Card.Section>

        <Stack p="md">
          <Descriptions
            items={[
              { label: "Space", children: data.processById.spaceTitle },
              { label: "Priority", children: data.processById.priority },
            ]}
          />

          {data.processById.description && (
            <Descriptions
              title="Description"
              items={[{ children: data.processById.description }]}
            />
          )}

          <Descriptions
            title="Trigger"
            items={[
              data.processById.triggerSchedule?.frequency
                ? {
                    label: "Frequency",
                    children: (
                      <Group>
                        <Text>
                          {data.processById.triggerSchedule?.frequency}
                        </Text>
                        <Group>
                          <Button
                            variant="subtle"
                            onClick={() =>
                              setTriggerModalState(ModalStates.EDIT)
                            }
                          >
                            Edit
                          </Button>
                          <Button
                            variant="subtle"
                            color="red"
                            onClick={() => handleDeleteScheule()}
                          >
                            Delete
                          </Button>
                        </Group>
                      </Group>
                    ),
                  }
                : {
                    label: "Status",
                    children: (
                      <Group>
                        <Text>Off</Text>
                        <Button
                          variant="subtle"
                          onClick={() => handleCreateTriggerSchedule()}
                        >
                          Create
                        </Button>
                      </Group>
                    ),
                  },
            ]}
          />
        </Stack>
      </Card>

      <Modal
        zIndex={10000}
        title={<Text fw={700}>Edit Process</Text>}
        opened={processModalState !== ModalStates.OFF}
        onClose={handleCancel}
        centered
        size={669}
      >
        <ProcessForm
          onSave={handleSaveProcess}
          onCancel={handleCancel}
          initialState={"EDIT"}
          selectedRow={data.processById}
        />
      </Modal>

      <Modal
        title={
          triggerModalState === ModalStates.ADD
            ? "Create Trigger Schedule"
            : "Edit Trigger Schedule"
        }
        opened={triggerModalState !== ModalStates.OFF}
        onClose={handleCancel}
        centered
      >
        {data && data.processById && (
          <TriggerScheduleForm
            onSave={handleSaveTriggerSchedule}
            onCancel={handleCancel}
            initialState={
              triggerModalState === ModalStates.ADD ? "ADD" : "EDIT"
            }
            data={data.processById}
          />
        )}
      </Modal>

      <Tabs defaultValue="cycles" mt="md">
        <Tabs.List>
          <Tabs.Tab value="cycles">Cycles</Tabs.Tab>
          <Tabs.Tab value="procedures">Procedures</Tabs.Tab>
        </Tabs.List>

        {tabs.map((tab) => (
          <Tabs.Panel key={tab.value} value={tab.value}>
            {tab.content}
          </Tabs.Panel>
        ))}
      </Tabs>
    </div>
  );
}

export default ProcessDetails;
