import { useState, useEffect } from "react";
import { TextInput, Button, Stack, Box } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Description } from "../../components/Description";

interface SpaceFormProps {
  onSave: (values: any) => void;
  onCancel: () => void;
  initialState: "ADD" | "EDIT";
  selectedSpace?: any;
  tenantId?: string;
}

export const SpaceForm: React.FC<SpaceFormProps> = ({
  onSave,
  onCancel,
  initialState,
  selectedSpace,
  tenantId,
}) => {
  const [isEditing] = useState(initialState === "EDIT");

  const form = useForm({
    initialValues: {
      id: null,
      title: "",
      description: "",
      tenantId: tenantId || "",
    },
    validate: {
      title: (value) => (value ? null : "Title is required"),
    },
  });

  useEffect(() => {
    if (initialState === "EDIT" && selectedSpace) {
      form.setValues({
        id: selectedSpace.id,
        title: selectedSpace.title,
        description: selectedSpace.description || "",
        tenantId: tenantId || selectedSpace.tenantId || "",
      });
    }
  }, [initialState, selectedSpace, tenantId]);

  const handleSave = async () => {
    const validation = form.validate();
    if (!validation.hasErrors) {
      onSave(form.values);
      onCancel();
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSave();
      }}
    >
      <Stack gap="md">
        <TextInput
          label="Title"
          placeholder="Enter space title"
          required
          {...form.getInputProps("title")}
        />

        <Description
          markdown={initialState === "EDIT" ? selectedSpace?.description : ""}
          onChange={(markdown: string) =>
            form.setFieldValue("description", markdown)
          }
          showToolbar={form.isDirty()}
        />

        <Box style={{ display: "flex", gap: "8px" }}>
          <Button variant="outline" onClick={onCancel}>
            {isEditing ? "Cancel" : "Cancel"}
          </Button>
          <Button
            type="submit"
            variant="filled"
            disabled={form.isDirty() === false}
            style={{ marginLeft: "auto" }}
          >
            {isEditing ? "Save Changes" : "Create Space"}
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default SpaceForm;
