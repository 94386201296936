import { Checkbox, Group, Stack } from "@mantine/core";
import { useState, useEffect } from "react";

const daysOfWeekOptions = [
  { label: "Sun", value: "SUNDAY" },
  { label: "Mon", value: "MONDAY" },
  { label: "Tue", value: "TUESDAY" },
  { label: "Wed", value: "WEDNESDAY" },
  { label: "Thu", value: "THURSDAY" },
  { label: "Fri", value: "FRIDAY" },
  { label: "Sat", value: "SATURDAY" },
];

// Correctly destructure props here
const DaysOfWeek: React.FC<{
  days: string[];
  onChange: (checkedValues: string[]) => void;
}> = ({ days, onChange }) => {
  // Initialize selectedDays with the days prop
  const [selectedDays, setSelectedDays] = useState(days);

  // Update selectedDays when days prop changes
  useEffect(() => {
    setSelectedDays(days);
  }, [days]);

  const handleChange = (day: string, checked: boolean) => {
    let newSelectedDays: string[];

    if (checked) {
      newSelectedDays = [...selectedDays, day];
    } else {
      newSelectedDays = selectedDays.filter((d) => d !== day);
    }

    setSelectedDays(newSelectedDays);
    if (typeof onChange === "function") {
      onChange(newSelectedDays);
    }
  };

  return (
    <Group>
      {daysOfWeekOptions.map((option) => (
        <Checkbox
          key={option.value}
          label={option.label}
          checked={selectedDays.includes(option.value)}
          onChange={(event) =>
            handleChange(option.value, event.currentTarget.checked)
          }
        />
      ))}
    </Group>
  );
};

export default DaysOfWeek;
