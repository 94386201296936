import { useState } from "react";
import { Button, Stack, Box } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Description } from "../../components/Description";

export interface ActionCycleFormValues {
  id: string;
  description: string;
  state: "ADD" | "EDIT";
}

interface ActionCycleFormProps {
  onSave: (values: ActionCycleFormValues) => void;
  onCancel: () => void;
  initialState: "ADD" | "EDIT";
  selectedRow: any;
}

export const ActionCycleForm: React.FC<ActionCycleFormProps> = ({
  onSave,
  onCancel,
  initialState,
  selectedRow,
}) => {
  const [descriptionKey, setDescriptionKey] = useState(0);

  const form = useForm<ActionCycleFormValues>(
    initialState === "EDIT"
      ? {
          initialValues: {
            id: selectedRow.id,
            description: selectedRow.description,
            state: "EDIT",
          },
        }
      : {
          initialValues: {
            id: "",
            description: "",
            state: "ADD",
          },
        }
  );

  const handleSave = async () => {
    try {
      const validation = form.validate();
      if (!validation.hasErrors) {
        onSave(form.values);
        onCancel();
      }
    } catch (error) {
      console.error("Validation error:", error);
    }
  };

  const handleCancel = () => {
    form.reset();
    form.setFieldValue("state", "ADD");
    setDescriptionKey((k) => k + 1);
    onCancel();
  };

  return (
    <form onSubmit={form.onSubmit(handleSave)}>
      <Stack>
        <Description
          key={descriptionKey}
          markdown={form.values.description}
          onChange={(markdown) => form.setFieldValue("description", markdown)}
        />
        <Box
          style={{ display: "flex", gap: "8px", justifyContent: "flex-end" }}
        >
          <Button type="submit" variant="filled" color="blue">
            Save
          </Button>
          <Button variant="outline" color="red" onClick={handleCancel}>
            Cancel
          </Button>
        </Box>
      </Stack>
    </form>
  );
};
