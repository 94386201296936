import React, { ReactNode } from "react";
import { AppShell, Container, Breadcrumbs, Text, Anchor } from "@mantine/core";
import { IconHome, IconUser, IconApps } from "@tabler/icons-react";
import { Link, useLocation } from "react-router-dom";
import { SpaceContext } from "./contexts/SpaceContext";
import { ProcessContext } from "./contexts/ProcessContext";

interface AppLayoutProps {
  children: ReactNode;
  pageTitle: string;
}

export const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  pageTitle,
}) => {
  const { space, setSpace } = React.useContext(SpaceContext);
  const { process, setProcess } = React.useContext(ProcessContext);
  const breadcrumbItems = [];

  breadcrumbItems.push({
    title: (
      <Anchor component={Link} to="/">
        <IconHome size={16} />
      </Anchor>
    ),
    key: "home",
  });

  if (space.cSpaceName) {
  }
  if (process.cProcessName) {
    breadcrumbItems.push({
      title: (
        <Anchor component={Link} to={"/space/" + space.cSpaceId}>
          {space.cSpaceName}
        </Anchor>
      ),
      key: "space",
    });
  }

  return (
    <AppShell padding="md" header={{ height: 72 }} bg="#F5F1EE">
      <AppShell.Header p="xs" style={{ backgroundColor: "#000102" }}>
        <Container
          size="100%"
          style={{ display: "flex", alignItems: "center", height: "100%" }}
        >
          <img
            src="/shorton_logo.png"
            alt="Shorton Logo"
            style={{ height: "24px" }}
          />
        </Container>
      </AppShell.Header>
      <AppShell.Main>
        <Container size="100%" py="md">
          {/*
            Use startTransition to control rendering priority if needed
            startTransition(() => {
              {children}
            });
          */}
          {children}
        </Container>
      </AppShell.Main>
    </AppShell>
  );
};

export default AppLayout;
