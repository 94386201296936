import { Link } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import {
  ProcedureCycle,
  ProcessCycle,
  StepCycle,
  WorkPriority,
  WorkStatus,
} from "../../gql/graphql"; // Assuming ProcessCycle is the correct type
import { ProcessInfo } from "../../shared/interfaces";
import {
  Alert,
  Button,
  Card,
  Drawer,
  List,
  Modal,
  Table,
  Stack,
  Group,
  Text,
  Checkbox,
} from "@mantine/core";
import { IconPencil, IconCircleXFilled } from "@tabler/icons-react";
// Removed the import for "@mantine/icons" due to the error

import React, { useEffect, useState } from "react";
// import CycleTree from "../misc/CycleTree";
import ProcessCycleForm from "../forms/ProcessCycleForm";
import ReactDOM from "react-dom";
import { CYCLE_DETAILS } from "../../shared/fragments";
import { SelectProcessCycleStatus } from "../../components/SelectStatus";
import {
  CREATE_PROCESS_CYCLE,
  UPDATE_PROCESS_CYCLE,
} from "../../shared/mutations";
// import { format, formatDistanceToNow, isPast, isToday } from "date-fns";
import { isMobile } from "react-device-detect";
import { DataTable } from "mantine-datatable";
import _ from "lodash";
import { StatusField } from "../../components/fields/StatusField";
import { PriorityField } from "../../components/fields/PriorityField";
import { ProjectView } from "../misc/ProjectView";
import { DeadlineField } from "../../components/fields/DeadlneField";

// Define the GraphQL query
const GET_PROCESS_CYCLES_INFO = gql`
  ${CYCLE_DETAILS}
  query getProcessCyclesByProcess($id: ID!) {
    processCyclesByProcess(id: $id) {
      ...CycleDetails
    }
  }
`;
// Define the GraphQL query
const GET_TENANT_CYCLES_INFO = gql`
  ${CYCLE_DETAILS}
  query getProcessCyclesByTenant($id: ID!) {
    processCyclesByTenant(id: $id) {
      ...CycleDetails
    }
  }
`;

// Define the GraphQL query
const GET_SPACE_CYCLES_INFO = gql`
  ${CYCLE_DETAILS}
  query getProcessCyclesBySpace($id: ID!) {
    processCyclesBySpace(id: $id) {
      ...CycleDetails
    }
  }
`;

export function useCreateProcessCycleMutation() {
  return useMutation(CREATE_PROCESS_CYCLE);
}

export const UPDATE_PROCESS_CYCLES_STATUS = gql`
  mutation updateProcessCyclesStatus($ids: [ID!]!, $status: WorkStatus!) {
    updateProcessCyclesStatus(ids: $ids, status: $status) {
      id
      status
    }
  }
`;
export function useUpdateProcessCyclesStatusMutation() {
  return useMutation(UPDATE_PROCESS_CYCLES_STATUS);
}

// Add cyclesBy type prop with values "PROCESS" or "SPACE"
interface ProjectTableProps {
  cyclesBy: "PROCESS" | "SPACE" | "TENANT";
  id: string | null;
}

const ProjectTable: React.FC<ProjectTableProps> = ({ id, cyclesBy }) => {
  const ModalStates = {
    OFF: "OFF",
    ADD: "ADD",
    EDIT: "EDIT",
  };

  const [hoveredRow, setHoveredRow] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [selectedCycleId, setSelectedCycleId] = useState("");
  const [selectedCycleStatus, setSelectedCycleStatus] = useState("");
  const [cycleTreeData, setCycleTreeData] = useState<string | null>(null);
  const [cycleTreeChecked, setCycleTreeChecked] = useState<string[]>([]);
  const [cycleTreeExpanded, setCycleTreeExpanded] = useState<string[]>([]);
  const [modalState, setModalState] = useState(ModalStates.OFF);
  const [selectedRow, setSelectedRow] = useState<ProcessInfo | null>(null);
  const [tableData, setTableData] = useState<ProcessInfo[]>([]);
  const [cyclesData, setCyclesData] = useState<ProcessCycle[]>([]);
  function useUpdateProcessCycleMutation() {
    return useMutation(UPDATE_PROCESS_CYCLE);
  }
  const [updateProcessCycle, { data: updateProcessCycleData }] =
    useUpdateProcessCycleMutation();
  const [createProcessCycle, { data: createProcessCycleData }] =
    useCreateProcessCycleMutation();
  const [updateProcessCyclesStatus] = useUpdateProcessCyclesStatusMutation();

  const { loading, error, data, refetch } = useQuery(
    cyclesBy === "PROCESS"
      ? GET_PROCESS_CYCLES_INFO
      : cyclesBy === "TENANT"
      ? GET_TENANT_CYCLES_INFO
      : GET_SPACE_CYCLES_INFO,
    {
      variables: { id },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (!loading) {
      const result =
        cyclesBy === "PROCESS"
          ? data?.processCyclesByProcess
          : cyclesBy === "TENANT"
          ? data?.processCyclesByTenant
          : data?.processCyclesBySpace;
      setTableData(
        result?.map((cycle: ProcessCycle) => {
          // Find the first procedure cycle where the status is not "Skipped" or "Done"
          const currentProcedureCycle = cycle.procedureCycles?.find(
            (procedureCycle) =>
              procedureCycle?.status !== "Skipped" &&
              procedureCycle?.status !== "Done"
          );

          return {
            key: cycle.id,
            cycle: cycle.title,
            status: cycle.status,
            priority: cycle.priority,
            description: cycle.description || "",
            deadline: cycle.deadline || "",
            process: cycle.process?.title || "",
            spaceTitle: cycle.process?.spaceTitle || "",
            spaceId: cycle.process?.spaceId || "",
            processId: cycle.process?.id || "",
            // Add the currentProcedure property
            currentProcedure: currentProcedureCycle?.procedure?.title || "",
            selected: false,
          };
        })
      );
      setCyclesData(result || []);
      if (selectedCycleId && result) {
        result.map((cycle: ProcessCycle) => {
          if (cycle.id === selectedCycleId) {
            setSelectedCycleStatus(cycle.status || "");
          }
        });
      }
      console.log("data from processCycles", data);
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;

  if (error) {
    if (
      error.networkError &&
      "statusCode" in error.networkError &&
      error.networkError.statusCode === 401
    ) {
      window.location.reload();
      return null;
    }
    return <p>Error: {error.message}</p>;
  }

  const handleCancel = () => {
    setModalState(ModalStates.OFF);
  };

  const handleEditCycle = (entity: any) => {
    setSelectedRow(entity);
    setModalState(ModalStates.EDIT);
  };

  const handleSaveProcessCycle = async (processCycle: any) => {
    if (modalState === ModalStates.ADD) {
      try {
        const result = await createProcessCycle({
          variables: {
            title: processCycle.title,
            description: processCycle.description,
            processId: id,
            status: WorkStatus.ToDo,
            priority: processCycle.priority,
          },
        });

        const currentProcedureCycle =
          result.data.createProcessCycle.procedureCycles?.find(
            (procedureCycle: any) =>
              procedureCycle?.status !== "Skipped" &&
              procedureCycle?.status !== "Done"
          );
        const newTableData: ProcessInfo = {
          key: result.data.createProcessCycle.id,
          cycle: result.data.createProcessCycle.title,
          status: result.data.createProcessCycle.status,
          process: result.data.createProcessCycle.process.title,
          priority: result.data.createProcessCycle.priority,
          deadline: result.data.createProcessCycle.deadline,
          description: result.data.createProcessCycle.description,
          processId: result.data.createProcessCycle.process.id,
          currentProcedure: currentProcedureCycle?.procedure?.title || "",
          selected: false,
        };

        setTableData([...tableData, newTableData]);
        setCyclesData([...cyclesData, result.data.createProcessCycle]);

        // Handle the result if needed
        console.log("Mutation result:", result);
      } catch (error) {
        // Handle the error if the mutation fails
        console.error("Mutation error:", error);
      }
    } else if (modalState === ModalStates.EDIT) {
      try {
        const result = await updateProcessCycle({
          variables: {
            id: processCycle.id, // Provide the processCycle ID to identify which processCycle to update
            title: processCycle.title,
            description: processCycle.description,
            priority: processCycle.priority,
            deadline: processCycle.deadline,
            status: processCycle.status,
          },
        });
        const currentProcedureCycle =
          result.data.updateProcessCycle.procedureCycles?.find(
            (procedureCycle: any) =>
              procedureCycle?.status !== "Skipped" &&
              procedureCycle?.status !== "Done"
          );
        setTableData((tabbleData) =>
          tableData.map((item) =>
            item.key === processCycle.id
              ? {
                  key: result.data.updateProcessCycle.id,
                  id: result.data.updateProcessCycle.id,
                  cycle: result.data.updateProcessCycle.title,
                  process: result.data.updateProcessCycle.process.title,
                  priority: result.data.updateProcessCycle.priority,
                  deadline: result.data.updateProcessCycle.deadline,
                  status: result.data.updateProcessCycle.status,
                  processId: result.data.updateProcessCycle.process.id,
                  description: result.data.updateProcessCycle.description,
                  currentProcedure:
                    currentProcedureCycle?.procedure?.title || "",
                  selected: false,
                }
              : item
          )
        );
        setCyclesData((cyclesData) =>
          cyclesData.map((item) =>
            item.id === result.data.updateProcessCycle.id
              ? result.data.updateProcessCycle
              : item
          )
        );
        // Handle the result if needed
        console.log("Mutation result:", result);
      } catch (error) {
        // Handle the error if the mutation fails
        console.error("Mutation error:", error);
      }
    }
    setModalState(ModalStates.OFF);
  };

  const handleMouseEnter = (record: any) => {
    setHoveredRow(record.key);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const updateCycle = async (record: any) => {
    try {
      const result = await updateProcessCycle({
        variables: {
          id: record.key, // Provide the processCycle ID to identify which processCycle to update
          title: record.cycle,
          status: record.status as WorkStatus,
          priority: record.priority as WorkPriority,
          deadliine: record.deadline,
        },
      });
    } catch (error) {
      // Handle the error here
      console.error(error);
    }
  };

  const setCycleStatus = (record: any, status: WorkStatus) => {
    record.status = status;
    updateCycle(record);
  };

  const onSelectProcessCycleStatus = async (ids: string[], status: string) => {
    try {
      console.log("onSelectStepStatus", status);
      await updateProcessCyclesStatus({
        variables: {
          ids: ids,
          status: status.replace(" ", "_"),
        },
      });
      refetch && refetch();
    } catch (error) {
      console.error(
        "Error updating step cycles" + ids + " status to " + status,
        error
      );
    }
  };

  // Filtering data
  let uniqueStatus: string[] = [];
  let uniqueProcess: String[] = [];

  cyclesData?.map((cycle: ProcessCycle) => {
    if (uniqueStatus.indexOf(cycle.status as string) === -1) {
      uniqueStatus.push(cycle.status as string);
    }
    if (uniqueProcess.indexOf(cycle.process?.title as string) === -1) {
      uniqueProcess.push(cycle.process?.title as string);
    }
  });

  const columns = [
    {
      accessor: "selection",
      title: (
        <div
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const allSelected = tableData.every((item) => item.selected);
            setTableData(
              tableData.map((item) => ({
                ...item,
                selected: !allSelected,
              }))
            );
          }}
        >
          <Checkbox
            className="mantine-projecttable-Checkbox-input"
            checked={
              tableData.length > 0 && tableData.every((item) => item.selected)
            }
            indeterminate={
              tableData.some((item) => item.selected) &&
              !tableData.every((item) => item.selected)
            }
            readOnly
            color="#9E9E9E"
            size="md"
          />
        </div>
      ),
      width: 40,
      render: (record: any) => (
        <div
          // style={{ width: "100%", height: "100%", cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setTableData(
              tableData.map((item) =>
                item.key === record.key
                  ? { ...item, selected: !item.selected }
                  : item
              )
            );
          }}
        >
          <Checkbox
            className="mantine-projecttable-Checkbox-input"
            checked={record.selected || false}
            readOnly
            color="#9E9E9E"
            size="md"
            height={14}
            width={14}
          />
        </div>
      ),
    },
    {
      accessor: "spaceTitle",
      title: "Space",
      hidden: cyclesBy !== "TENANT" || isMobile,
      ellipsis: true,
    },
    {
      accessor: "process",
      title: "Process",
      render: (record: any) => (
        <Link to={`/process/${record.processId}`}>{record.process}</Link>
      ),
      hidden: cyclesBy === "PROCESS",
      ellipsis: true,
    },
    {
      accessor: "cycle",
      title: "Project",
      ellipsis: true,
      width: 400,
    },
    {
      accessor: "status",
      title: "Status",
      render: (record: any) => (
        <StatusField
          status={record.status}
          currentProcedure={record.currentProcedure}
        />
      ),
      noWrap: true,
    },
    {
      accessor: "priority",
      title: "Priority",
      noWrap: true,
      render: (record: any) => <PriorityField priority={record.priority} />,
    },
    {
      accessor: "deadline",
      title: "Deadline",
      noWrap: true,
      render: (record: any) => <DeadlineField deadline={record.deadline} />,
    },
    {
      accessor: "actions",
      title: "",
      noWrap: true,

      render: (record: any) => (
        <div
          style={{ height: "22px", display: "flex", alignItems: "center" }}
          onClick={(e) => e.stopPropagation()}
        >
          {(hoveredRow === record.key || isMobile || true) && (
            <div>
              <Button
                variant="subtle"
                leftSection={<IconPencil size={16} />}
                onClick={(e) => {
                  handleEditCycle(record);
                  e.stopPropagation();
                }}
              />
            </div>
          )}
        </div>
      ),
    },
  ];

  const handleAddCycle = () => {
    setModalState(ModalStates.ADD);
  };

  const TableHeader: React.FC<{ headerTitle: string }> = ({ headerTitle }) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          marginLeft: "10px",
        }}
      >
        <div className="title">
          {headerTitle}
          {isMobile && (
            <span>
              &nbsp; (
              {
                tableData.filter(
                  (record) => !"Done;Skipped;".includes(record.status)
                ).length
              }
              )
            </span>
          )}
        </div>
        {cyclesBy === "PROCESS" && (
          <Button size="xs" onClick={handleAddCycle}>
            Add Project
          </Button>
        )}
      </div>
    );
  };

  function sortData(data: ProcessInfo[]) {
    return _.orderBy(
      data,
      [
        (item) => (item.priority === "Critical" ? 0 : 1),
        (item) => (item.deadline ? 0 : 1),
        "deadline",
        (item) =>
          item.priority === "Critical"
            ? 1
            : item.priority === "High"
            ? 2
            : item.priority === "Medium"
            ? 3
            : item.priority === "Low"
            ? 4
            : 5,
      ],
      ["asc", "asc", "asc"]
    );
  }

  function openDrawer(record: any) {
    ReactDOM.unstable_batchedUpdates(() => {
      const key = record.key;

      setCycleTreeData(key);

      console.log("data", data, "key", key);

      // create a flat array of checked keys, based on step cycle id's where status is "Done"
      const checked: string[] = cyclesData
        .filter((cycle: ProcessCycle) => cycle.id === key)
        .flatMap((cycle: ProcessCycle) =>
          (cycle.procedureCycles || []).flatMap(
            (procedureCycle: ProcedureCycle | null | undefined) => {
              if (!procedureCycle) return []; // Handle null and undefined values

              return (procedureCycle.stepCycles || []).map(
                (stepCycle: StepCycle | null | undefined) => {
                  if (!stepCycle) return null; // Handle null and undefined values

                  return stepCycle.status === WorkStatus.Done
                    ? stepCycle.id
                    : null;
                }
              );
            }
          )
        )
        .filter((id: string | null) => id !== null) as string[]; // Filter out null values and cast to string[]
      setCycleTreeChecked(checked);

      let firstAllToDo = true;

      const expanded: string[] = cyclesData
        .filter((cycle: ProcessCycle) => cycle.id === key)
        .flatMap((cycle: ProcessCycle) => {
          return (cycle.procedureCycles || []).flatMap(
            (
              procedureCycle: ProcedureCycle | null | undefined,
              index: number,
              array: (ProcedureCycle | null | undefined)[]
            ) => {
              if (!procedureCycle) return []; // Handle null and undefined values

              // Check if any step cycle is not in status Skip or Done
              const hasActiveStep = (procedureCycle.stepCycles || []).some(
                (stepCycle: StepCycle | null | undefined) => {
                  if (!stepCycle) return false; // Handle null and undefined values
                  return (
                    stepCycle.status !== WorkStatus.Skipped &&
                    stepCycle.status !== WorkStatus.Done
                  );
                }
              );

              // Check if all step cycles are in status ToDo
              const allToDo = (procedureCycle.stepCycles || []).every(
                (stepCycle: StepCycle | null | undefined) => {
                  if (!stepCycle) return false; // Handle null and undefined values
                  return stepCycle.status === WorkStatus.ToDo;
                }
              );

              // Include the first procedure cycle or if it has at least one active step cycle
              if ((allToDo && firstAllToDo) || (hasActiveStep && !allToDo)) {
                // Update firstAllToDo
                firstAllToDo = false;
                return procedureCycle.id || null;
              }

              return null;
            }
          );
        })
        .filter((id: string | null) => id !== null) as string[]; // Filter out null values and cast to string[]
      setCycleTreeExpanded(expanded);
      setDrawerTitle(record.cycle);
      setSelectedCycleId(record.key);
      setSelectedCycleStatus(record.status);
      setDrawerVisible(true);
    });
  }

  return (
    <Group align="flex-start" style={{ height: "calc(100vh - 100px)" }}>
      <div style={{ display: "flex", flex: 2, height: "100%" }}>
        {isMobile && (
          <List>
            <List.Item>
              {TableHeader({
                headerTitle:
                  cyclesBy === "PROCESS"
                    ? "Process Projects"
                    : cyclesBy === "TENANT"
                    ? "All Projects"
                    : "Space Projects",
              })}
            </List.Item>
            {sortData(tableData)
              .filter((record) => !"Done;Skipped;".includes(record.status))
              .map((record: ProcessInfo) => {
                return (
                  <List.Item onClick={() => openDrawer(record)}>
                    <Card>
                      <Stack>
                        <Group justify="space-between">
                          <Text fw={500}>{record.cycle}</Text>
                          <Button
                            variant="subtle"
                            leftSection={<IconPencil size={16} />}
                            onClick={(e) => {
                              handleEditCycle(record);
                              e.stopPropagation();
                            }}
                          />
                        </Group>
                        <Text>
                          <strong>Status:</strong>{" "}
                          {record.status.replace("_", " ") +
                            ("In_Progress;To_Do".includes(record.status) &&
                            record.currentProcedure
                              ? " (" + record.currentProcedure + ")"
                              : "")}
                        </Text>
                        <Text>
                          <strong>Priority:</strong> {record.priority}
                        </Text>
                        {cyclesBy !== "PROCESS" && (
                          <Text>
                            <strong>Process:</strong>{" "}
                            <Link to={`/process/${record.processId}`}>
                              {record.process}
                            </Link>
                          </Text>
                        )}
                        {record.deadline && (
                          <Text>
                            <strong>Deadline:</strong>{" "}
                            <DeadlineField deadline={record.deadline} />
                          </Text>
                        )}
                      </Stack>
                    </Card>
                  </List.Item>
                );
              })}
          </List>
        )}
        {!isMobile && (
          <div
            className="mantine-wrapper"
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <TableHeader
              headerTitle={
                cyclesBy === "PROCESS"
                  ? "Process Projects"
                  : cyclesBy === "TENANT"
                  ? "All Projects"
                  : "Space Projects"
              }
            />
            <div style={{ flex: 1, overflow: "auto" }}>
              <DataTable
                records={sortData(tableData)}
                columns={columns}
                onRowClick={({ record, index, event }) => {
                  setSelectedRow(record);
                  openDrawer(record);
                }}
                highlightOnHover
                rowStyle={(record) => ({
                  backgroundColor:
                    record.key === selectedRow?.key ? "#F6FAFD" : undefined,
                })}
              />
            </div>
          </div>
        )}
        <Modal
          // zIndex={10000}
          title={
            modalState === ModalStates.ADD ? "Add Project" : "Edit Project"
          }
          opened={modalState !== ModalStates.OFF}
          onClose={handleCancel}
          withCloseButton={false}
          styles={{
            body: {
              // Ensure modal content has a z-index context
              position: "relative",
              zIndex: "auto",
            },
          }}
        >
          <ProcessCycleForm
            onSave={handleSaveProcessCycle}
            onCancel={handleCancel}
            initialState={modalState === ModalStates.ADD ? "ADD" : "EDIT"}
            selectedRow={selectedRow}
          />
        </Modal>
      </div>
      <div
        style={{
          flex: 1,
          display: selectedRow ? "flex" : "none",
          paddingRight: "16px",
          gap: "16px",
          flexDirection: "column",
        }}
        className="mantine-wrapper"
      >
        <div
          className="title"
          style={{
            gap: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            {drawerTitle}
            <PriorityField priority={selectedRow?.priority ?? "None"} />
          </div>
          <IconCircleXFilled
            size={20}
            style={{ cursor: "pointer" }}
            onClick={() => setSelectedRow(null)}
            color="var(--mantine-color-gray-6)"
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "16px",
          }}
        >
          <SelectProcessCycleStatus
            processCycleId={selectedCycleId}
            defaultValue={selectedCycleStatus + ""}
            onSelectStatus={onSelectProcessCycleStatus}
          />
          <DeadlineField deadline={selectedRow?.deadline ?? ""} />
        </div>

        {cycleTreeData && (
          <ProjectView
            processCycleId={cycleTreeData}
            checked={cycleTreeChecked}
            expanded={cycleTreeExpanded}
            refetch={refetch}
          />
        )}
      </div>
    </Group>
  );
};

export default ProjectTable;
