import { Accordion, Box, Card, Group, Checkbox, Text } from "@mantine/core";
import { gql, useMutation, useQuery } from "@apollo/client";
import { WorkStatus } from "../../gql/graphql";
import {
  SelectProcedureCycleStatus,
  SelectStepCycleStatus,
  SelectActionCycleStatus,
} from "../../components/SelectStatus";
import { CYCLE_DETAILS } from "../../shared/fragments";
import { Description } from "../../components/Description";
import { ShowDescription } from "../../components/fields/ShowDescription";
import {
  UPDATE_PROCEDURE_CYCLE,
  UPDATE_PROCEDURE_CYCLES_STATUS,
  UPDATE_STEP_CYCLE,
  UPDATE_STEP_CYCLES_STATUS,
  UPDATE_ACTION_CYCLE,
  UPDATE_ACTION_CYCLES_STATUS,
} from "../../shared/mutations";
import StepCycleForm from "../forms/StepCycleForm";
import { ActionCycleForm } from "../../pages/forms/ActionCycleForm";
import type { ActionCycleFormValues } from "../../pages/forms/ActionCycleForm";
import { useState } from "react";
import { PriorityField } from "../../components/fields/PriorityField";

const GET_CYCLE_INFO = gql`
  ${CYCLE_DETAILS}
  query getProcessCycleById($id: ID!) {
    processCycleById(id: $id) {
      ...CycleDetails
    }
  }
`;

interface ProjectViewProps {
  processCycleId: string;
  checked: string[];
  expanded: string[];
  refetch?: () => void;
}

export function ProjectView({
  processCycleId,
  checked,
  expanded,
  refetch,
}: ProjectViewProps) {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [checkedItems, setCheckedItems] = useState<string[]>(checked);
  const { data, loading } = useQuery(GET_CYCLE_INFO, {
    variables: { id: processCycleId },
  });

  const [updateStepCycle] = useMutation(UPDATE_STEP_CYCLE);
  const [updateProcedureCycle] = useMutation(UPDATE_PROCEDURE_CYCLE);
  const [updateActionCycle] = useMutation(UPDATE_ACTION_CYCLE);

  const [updateStepCyclesStatus] = useMutation(UPDATE_STEP_CYCLES_STATUS);
  const [updateProcedureCyclesStatus] = useMutation(
    UPDATE_PROCEDURE_CYCLES_STATUS
  );
  const [updateActionCyclesStatus] = useMutation(UPDATE_ACTION_CYCLES_STATUS);

  if (loading || !data) return null;

  const updateStepCycleDescription = async (
    id: string,
    description: string
  ) => {
    const allStepCycles = data?.processCycleById.procedureCycles.flatMap(
      (procedureCycle: any) => procedureCycle.stepCycles
    );
    const stepCycle = allStepCycles.find(
      (stepCycle: any) => stepCycle.id === id
    );

    try {
      await updateStepCycle({
        variables: {
          id,
          status: stepCycle.status,
          description: description || "",
        },
      });
    } catch (error) {
      console.error("Error updating step cycle description", error);
    }
  };

  const updateProcedureCycleDescription = async (
    id: string,
    description: string
  ) => {
    const procedureCycle = data?.processCycleById.procedureCycles.find(
      (procedureCycle: any) => procedureCycle.id === id
    );

    try {
      await updateProcedureCycle({
        variables: {
          id,
          status: procedureCycle.status,
          description,
        },
      });
    } catch (error) {
      console.error("Error updating procedure cycle description", error);
    }
  };

  const updateActionCycleDescription = async (
    id: string,
    description: string
  ) => {
    const allActionCycles = data?.processCycleById.procedureCycles.flatMap(
      (procedureCycle: any) =>
        procedureCycle.stepCycles.flatMap(
          (stepCycle: any) => stepCycle.actionCycles
        )
    );
    const actionCycle = allActionCycles.find(
      (actionCycle: any) => actionCycle.id === id
    );

    try {
      await updateActionCycle({
        variables: {
          id,
          status: actionCycle.status,
          description: description || "",
        },
      });
    } catch (error) {
      console.error("Error updating action cycle description", error);
    }
  };

  const handleCheckboxChange = async (id: string, isChecked: boolean) => {
    const newCheckedItems = isChecked
      ? [...checkedItems, id]
      : checkedItems.filter((item) => item !== id);

    setCheckedItems(newCheckedItems);

    const isProcedure = data?.processCycleById.procedureCycles.some(
      (proc: any) => proc.id === id
    );
    const isStep = data?.processCycleById.procedureCycles.some((proc: any) =>
      proc.stepCycles.some((step: any) => step.id === id)
    );
    const isAction = data?.processCycleById.procedureCycles.some((proc: any) =>
      proc.stepCycles.some((step: any) =>
        step.actionCycles.some((action: any) => action.id === id)
      )
    );

    try {
      if (isProcedure) {
        await updateProcedureCyclesStatus({
          variables: {
            ids: [id],
            status: isChecked ? WorkStatus.Done : WorkStatus.ToDo,
          },
        });
      } else if (isStep) {
        await updateStepCyclesStatus({
          variables: {
            ids: [id],
            status: isChecked ? WorkStatus.Done : WorkStatus.ToDo,
          },
        });
      } else if (isAction) {
        await updateActionCyclesStatus({
          variables: {
            ids: [id],
            status: isChecked ? WorkStatus.Done : WorkStatus.ToDo,
          },
        });
      }
      refetch?.();
    } catch (error) {
      console.error("Error updating status", error);
    }
  };

  return (
    <Accordion chevronPosition="left">
      {data.processCycleById.procedureCycles?.map((procedureCycle: any) => (
        <Accordion.Item key={procedureCycle.id} value={procedureCycle.id}>
          <Accordion.Control>
            <Group justify="space-between" wrap="nowrap" w="100%">
              <Group wrap="nowrap" style={{ flex: 1, minWidth: 0 }}>
                <Checkbox
                  checked={checkedItems.includes(procedureCycle.id)}
                  onChange={(event) =>
                    handleCheckboxChange(
                      procedureCycle.id,
                      event.currentTarget.checked
                    )
                  }
                  onClick={(e) => e.stopPropagation()}
                  size="xs"
                  color="black"
                  className="mantine-projectview-Checkbox-input"
                />
                <Text lineClamp={1}>{procedureCycle.procedure?.title}</Text>
                <PriorityField
                  priority={
                    procedureCycle?.priority ||
                    data.processCycleById.process.priority ||
                    null
                  }
                />
              </Group>
              <SelectProcedureCycleStatus
                procedureCycleId={procedureCycle.id}
                defaultValue={procedureCycle.status}
                onSelectStatus={(ids, status) => {
                  // Handle status update
                  refetch?.();
                }}
              />
            </Group>
          </Accordion.Control>

          <Accordion.Panel>
            <Group justify="left" w="100%">
              <Box style={{ marginLeft: "60px" }}>
                <ShowDescription
                  description={procedureCycle.procedure.description || ""}
                />
              </Box>
            </Group>
            {selectedItem === procedureCycle.id && (
              <Card mb="md">
                <Description
                  markdown={procedureCycle.description || ""}
                  onChange={(markdown) =>
                    updateProcedureCycleDescription(procedureCycle.id, markdown)
                  }
                />
              </Card>
            )}

            <Accordion chevronPosition="left">
              {procedureCycle.stepCycles?.map((stepCycle: any) => (
                <Accordion.Item key={stepCycle.id} value={stepCycle.id}>
                  <Accordion.Control>
                    <Group justify="space-between" wrap="nowrap" w="100%">
                      <Group wrap="nowrap" style={{ flex: 1, minWidth: 0 }}>
                        <Checkbox
                          className="mantine-projectview-Checkbox-input"
                          checked={checkedItems.includes(stepCycle.id)}
                          onChange={(event) =>
                            handleCheckboxChange(
                              stepCycle.id,
                              event.currentTarget.checked
                            )
                          }
                          onClick={(e) => e.stopPropagation()}
                          size="xs"
                          color="black"
                        />
                        <Text lineClamp={1}>{stepCycle.step?.title}</Text>
                      </Group>
                      <SelectStepCycleStatus
                        stepCycleId={stepCycle.id}
                        defaultValue={stepCycle.status}
                        onSelectStatus={(ids, status) => {
                          // Handle status update
                          refetch?.();
                        }}
                      />
                    </Group>
                  </Accordion.Control>

                  <Accordion.Panel>
                    <Group justify="left" w="100%">
                      <Box style={{ marginLeft: "60px" }}>
                        <ShowDescription
                          description={stepCycle.step.description || ""}
                        />
                      </Box>
                    </Group>
                    {selectedItem === stepCycle.id && (
                      <StepCycleForm
                        initialState="EDIT"
                        selectedRow={stepCycle}
                        onSave={(formData) =>
                          updateStepCycleDescription(
                            stepCycle.id,
                            formData.description
                          )
                        }
                        onCancel={() => setSelectedItem(null)}
                      />
                    )}
                    <Accordion chevronPosition="left">
                      {stepCycle.actionCycles?.map((actionCycle: any) => (
                        <Accordion.Item
                          key={actionCycle.id}
                          value={actionCycle.id}
                        >
                          <Accordion.Control>
                            <Group
                              justify="space-between"
                              wrap="nowrap"
                              w="100%"
                            >
                              <Group
                                wrap="nowrap"
                                style={{ flex: 1, minWidth: 0 }}
                              >
                                <Checkbox
                                  className="mantine-projectview-Checkbox-input"
                                  checked={checkedItems.includes(
                                    actionCycle.id
                                  )}
                                  onChange={(event) =>
                                    handleCheckboxChange(
                                      actionCycle.id,
                                      event.currentTarget.checked
                                    )
                                  }
                                  onClick={(e) => e.stopPropagation()}
                                  size="xs"
                                  color="black"
                                />
                                <Text lineClamp={1}>
                                  {actionCycle.action?.title}
                                </Text>
                              </Group>
                              <SelectActionCycleStatus
                                actionCycleId={actionCycle.id}
                                defaultValue={actionCycle.status}
                                onSelectStatus={(ids, status) => {
                                  // Handle status update
                                  refetch?.();
                                }}
                              />
                            </Group>
                          </Accordion.Control>
                          <Accordion.Panel>
                            <Group justify="left" w="100%">
                              <Box style={{ marginLeft: "60px" }}>
                                <ShowDescription
                                  description={
                                    actionCycle.action.description || ""
                                  }
                                />
                              </Box>
                            </Group>
                            {selectedItem === actionCycle.id && (
                              <ActionCycleForm
                                initialState="EDIT"
                                selectedRow={actionCycle}
                                onSave={(formData: ActionCycleFormValues) =>
                                  updateActionCycleDescription(
                                    actionCycle.id,
                                    formData.description
                                  )
                                }
                                onCancel={() => setSelectedItem(null)}
                              />
                            )}
                          </Accordion.Panel>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </Accordion.Panel>
                </Accordion.Item>
              ))}
            </Accordion>
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}
