import { useState } from "react";
import { TextInput, Button, Stack, Box, ActionIcon } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Description } from "../../components/Description";
import { IconCheck, IconX, IconSettings } from "@tabler/icons-react";
import { ProcessEntityConfigForm } from "./ProcessEntityConfigForm";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_PROCESS_CONFIG_BY_ENTITY } from "../../shared/queries";
import {
  CREATE_PROCESS_CONFIG,
  UPDATE_PROCESS_CONFIG,
} from "../../shared/mutations";

interface StepFormProps {
  onSave: (values: any) => void;
  onCancel: () => void;
  initialState: "ADD" | "EDIT";
  selectedRow: any;
}

export const StepForm: React.FC<StepFormProps> = ({
  onSave,
  onCancel,
  initialState,
  selectedRow,
}) => {
  const [isEditing] = useState(initialState === "EDIT");
  const [descriptionKey, setDescriptionKey] = useState(0);
  const [showConfigForm, setShowConfigForm] = useState(false);
  const [configFormState, setConfigFormState] = useState<"ADD" | "EDIT">("ADD");
  const [configData, setConfigData] = useState<any>(null);

  const [getProcessConfig, { loading: configLoading }] = useLazyQuery(
    GET_PROCESS_CONFIG_BY_ENTITY,
    {
      onCompleted: (data) => {
        if (data && data.processConfigByEntity) {
          setConfigData(data.processConfigByEntity);
          setConfigFormState("EDIT");
        } else {
          setConfigData(null);
          setConfigFormState("ADD");
        }
        setShowConfigForm(true);
      },
      onError: (error) => {
        console.error("Error fetching process config:", error);
        setConfigData(null);
        setConfigFormState("ADD");
        setShowConfigForm(true);
      },
    }
  );

  const [createProcessConfig] = useMutation(CREATE_PROCESS_CONFIG);
  const [updateProcessConfig] = useMutation(UPDATE_PROCESS_CONFIG);

  const form = useForm({
    initialValues:
      initialState === "EDIT" && selectedRow
        ? {
            id: selectedRow.id,
            title: selectedRow.title,
            description: selectedRow.description,
            state: "EDIT",
          }
        : {
            id: null,
            title: "",
            description: "",
            state: "ADD",
          },
  });

  // useEffect(() => {
  //     form.setValues({
  //       id: selectedRow.id,
  //       title: selectedRow.title,
  //       description: selectedRow.description,
  //       state: "EDIT",
  //     });
  //   } else {
  //     form.setValues({
  //       id: null,
  //       title: "",
  //       description: "",
  //       state: "ADD",
  //     });
  //   }
  // }, [initialState, selectedRow]);

  const handleSave = async () => {
    try {
      const validation = form.validate();
      if (!validation.hasErrors) {
        onSave(form.values);
        onCancel();
      }
    } catch (error) {
      console.error("Validation error:", error);
    }
  };

  const handleCancel = () => {
    form.reset();
    setDescriptionKey((k: number) => k + 1); // This will force Description to re-render
    onCancel();
  };

  const toggleConfigForm = () => {
    if (!showConfigForm && selectedRow?.id) {
      console.log("Fetching config for entity:", selectedRow.id, "Type: STEP");
      getProcessConfig({
        variables: {
          entityId: selectedRow.id,
          entityType: "STEP",
        },
        fetchPolicy: "network-only", // Force fetch from server, ignore cache
      });
    } else {
      setShowConfigForm(!showConfigForm);
    }
  };

  const handleConfigSave = async (values: any) => {
    try {
      if (configFormState === "ADD") {
        await createProcessConfig({
          variables: {
            entityId: selectedRow.id,
            entityType: "STEP",
            inputSource: values.inputSource,
            inputSourceConfiguration: values.inputSourceConfiguration,
            inputDescription: values.inputDescription,
            inputSample: values.inputSample,
            inputValidationType: values.inputValidationType,
            inputValidation: values.inputValidation,
            outputDescription: values.outputDescription,
            outputSample: values.outputSample,
            outputValidationType: values.outputValidationType,
            outputValidation: values.outputValidation,
          },
          onCompleted: (data) => {
            console.log("Config created successfully:", data);
            if (data && data.createProcessConfig) {
              setConfigData(data.createProcessConfig);
              setConfigFormState("EDIT");
            }
            setShowConfigForm(false);
          },
          onError: (error) => {
            console.error("Error creating process config:", error);
          },
          // Force a refetch of the config query after mutation
          refetchQueries: [
            {
              query: GET_PROCESS_CONFIG_BY_ENTITY,
              variables: {
                entityId: selectedRow.id,
                entityType: "STEP",
              },
            },
          ],
        });
      } else {
        await updateProcessConfig({
          variables: {
            id: values.id,
            inputSource: values.inputSource,
            inputSourceConfiguration: values.inputSourceConfiguration,
            inputDescription: values.inputDescription,
            inputSample: values.inputSample,
            inputValidationType: values.inputValidationType,
            inputValidation: values.inputValidation,
            outputDescription: values.outputDescription,
            outputSample: values.outputSample,
            outputValidationType: values.outputValidationType,
            outputValidation: values.outputValidation,
          },
          onCompleted: (data) => {
            console.log("Config updated successfully:", data);
            if (data && data.updateProcessConfig) {
              setConfigData(data.updateProcessConfig);
            }
            setShowConfigForm(false);
          },
          onError: (error) => {
            console.error("Error updating process config:", error);
          },
          // Force a refetch of the config query after mutation
          refetchQueries: [
            {
              query: GET_PROCESS_CONFIG_BY_ENTITY,
              variables: {
                entityId: selectedRow.id,
                entityType: "STEP",
              },
            },
          ],
        });
      }
    } catch (error) {
      console.error("Error saving process config:", error);
    }
  };

  return (
    <Stack gap="24">
      <Box component="form" onSubmit={form.onSubmit(handleSave)} py={24}>
        <Stack gap={24}>
          <input type="hidden" {...form.getInputProps("id")} />
          <input type="hidden" {...form.getInputProps("state")} />

          <TextInput
            label="Title"
            required
            {...form.getInputProps("title")}
            error={form.errors.title && "Please enter a title"}
          />

          <Description
            key={descriptionKey}
            markdown={
              initialState === "EDIT" && selectedRow
                ? selectedRow.description || ""
                : ""
            }
            onChange={(markdown) => form.setFieldValue("description", markdown)}
            showToolbar={form.isDirty()}
          />

          <Box
            style={{
              display: "flex",
              gap: "8px",
              justifyContent: "space-between",
            }}
          >
            <Box style={{ display: "flex", gap: "8px" }}>
              {(initialState !== "EDIT" || form.isDirty()) && (
                <>
                  <ActionIcon
                    type="submit"
                    variant="filled"
                    color="blue"
                    disabled={initialState === "ADD" && !form.isDirty()}
                    aria-label={
                      initialState === "EDIT" ? "Save Changes" : "Add Step"
                    }
                  >
                    <IconCheck size="1.125rem" />
                  </ActionIcon>
                  <ActionIcon
                    variant="outline"
                    color="red"
                    onClick={handleCancel}
                    disabled={initialState === "ADD" && !form.isDirty()}
                    aria-label={initialState === "EDIT" ? "Undo" : "Cancel"}
                  >
                    <IconX size="1.125rem" />
                  </ActionIcon>
                </>
              )}
            </Box>

            <ActionIcon
              variant="outline"
              color="gray"
              aria-label="Settings"
              onClick={toggleConfigForm}
            >
              <IconSettings size="1.125rem" />
            </ActionIcon>
          </Box>
        </Stack>
      </Box>

      {showConfigForm && (
        <Box mt={0}>
          <ProcessEntityConfigForm
            onSave={handleConfigSave}
            onCancel={() => setShowConfigForm(false)}
            initialState={configFormState}
            selectedRow={configData}
          />
        </Box>
      )}
    </Stack>
  );
};

export default StepForm;
