import { useState } from "react";
import { TextInput, Button, Stack, Box } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Description } from "../../components/Description";

interface StepCycleFormProps {
  onSave: (values: any) => void;
  onCancel: () => void;
  initialState: "ADD" | "EDIT";
  selectedRow: any;
}

export const StepCycleForm: React.FC<StepCycleFormProps> = ({
  onSave,
  onCancel,
  initialState,
  selectedRow,
}) => {
  const [isEditing] = useState(initialState === "EDIT");
  const [descriptionKey, setDescriptionKey] = useState(0);

  const form = useForm(
    initialState === "EDIT"
      ? {
          initialValues: {
            id: selectedRow.id,
            description: selectedRow.description,
            state: "EDIT",
          },
        }
      : {
          initialValues: {
            id: "",
            description: "",
            state: "ADD",
          },
        }
  );

  const handleSave = async () => {
    try {
      const validation = form.validate();
      if (!validation.hasErrors) {
        onSave(form.values);
        onCancel();
      }
    } catch (error) {
      console.error("Validation error:", error);
    }
  };

  const handleCancel = () => {
    form.reset();
    form.setFieldValue("state", "ADD");
    setDescriptionKey((k) => k + 1);
    onCancel();
  };

  return (
    <Box component="form" onSubmit={form.onSubmit(handleSave)} py={24}>
      <Stack gap={24}>
        <input type="hidden" {...form.getInputProps("id")} />
        <input type="hidden" {...form.getInputProps("state")} />

        <Description
          key={descriptionKey}
          markdown={selectedRow?.description ?? ""}
          onChange={(markdown) => form.setFieldValue("description", markdown)}
          showToolbar={form.isDirty()}
        />

        <Box style={{ display: "flex", gap: "8px" }}>
          <Button
            variant="outline"
            onClick={handleCancel}
            disabled={form.isDirty() === false}
          >
            {isEditing ? "Undo" : "Cancel"}
          </Button>
          <Button
            type="submit"
            variant="filled"
            disabled={form.isDirty() === false}
            style={{ marginLeft: "auto" }}
          >
            {isEditing ? "Save Changes" : "Add Step"}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default StepCycleForm;
