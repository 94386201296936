import { Menu, Text } from "@mantine/core";
import { WorkStatus } from "../gql/graphql";
import { useState } from "react";
import { StatusField } from "./fields/StatusField";

const options = Object.keys(WorkStatus).map((key: any, index) => ({
  key: key,
  label: Object.values(WorkStatus)[index] ?? key,
}));

interface ProcessCycleStatusProps {
  processCycleId: string;
  defaultValue: any;
  onSelectStatus: (ids: string[], status: string) => void;
}

interface ProcedureCycleStatusProps {
  procedureCycleId: string;
  defaultValue: any;
  onSelectStatus: (ids: string[], status: string) => void;
}

interface StepCycleStatusProps {
  stepCycleId: string;
  defaultValue: any;
  onSelectStatus: (ids: string[], status: string) => void;
}

interface ActionCycleStatusProps {
  actionCycleId: string;
  defaultValue: any;
  onSelectStatus: (ids: string[], status: string) => void;
}

export const SelectProcessCycleStatus: React.FC<ProcessCycleStatusProps> = ({
  processCycleId,
  defaultValue,
  onSelectStatus,
}) => {
  const [opened, setOpened] = useState(false);

  if (defaultValue === "DONE") {
    return <span />;
  }

  return (
    <Menu opened={opened} onChange={setOpened} position="bottom-end">
      <Menu.Target>
        <div onClick={() => setOpened(true)}>
          <StatusField status={defaultValue} />
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        {options.map(
          (option) =>
            option.label !== defaultValue && (
              <Menu.Item
                key={option.key}
                onClick={() => {
                  onSelectStatus([processCycleId], option.label);
                  setOpened(false);
                }}
              >
                <Text size="sm">{option.label.replace("_", " ")}</Text>
              </Menu.Item>
            )
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

export const SelectProcedureCycleStatus: React.FC<
  ProcedureCycleStatusProps
> = ({ procedureCycleId, defaultValue, onSelectStatus }) => {
  const [opened, setOpened] = useState(false);

  if (defaultValue === "DONE") {
    return <span />;
  }

  return (
    <Menu opened={opened} onChange={setOpened} position="bottom-end">
      <Menu.Target>
        <div onClick={() => setOpened(true)}>
          <StatusField status={defaultValue} />
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        {options.map(
          (option) =>
            option.label !== defaultValue && (
              <Menu.Item
                key={option.key}
                onClick={() => {
                  onSelectStatus([procedureCycleId], option.label);
                  setOpened(false);
                }}
              >
                <Text size="sm">{option.label.replace("_", " ")}</Text>
              </Menu.Item>
            )
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

export const SelectStepCycleStatus: React.FC<StepCycleStatusProps> = ({
  stepCycleId,
  defaultValue,
  onSelectStatus,
}) => {
  const [opened, setOpened] = useState(false);

  if (defaultValue === "DONE") {
    return <span />;
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Menu opened={opened} onChange={setOpened} position="bottom-end">
        <Menu.Target>
          <div onClick={() => setOpened(true)}>
            <StatusField status={defaultValue} />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          {options.map(
            (option) =>
              option.label !== defaultValue && (
                <Menu.Item
                  key={option.key}
                  onClick={() => {
                    onSelectStatus([stepCycleId], option.label);
                    setOpened(false);
                  }}
                >
                  <Text size="sm">{option.label.replace("_", " ")}</Text>
                </Menu.Item>
              )
          )}
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

export const SelectActionCycleStatus: React.FC<ActionCycleStatusProps> = ({
  actionCycleId,
  defaultValue,
  onSelectStatus,
}) => {
  const [opened, setOpened] = useState(false);

  if (defaultValue === "DONE") {
    return <span />;
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Menu opened={opened} onChange={setOpened} position="bottom-end">
        <Menu.Target>
          <div onClick={() => setOpened(true)}>
            <StatusField status={defaultValue} />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          {options.map(
            (option) =>
              option.label !== defaultValue && (
                <Menu.Item
                  key={option.key}
                  onClick={() => {
                    onSelectStatus([actionCycleId], option.label);
                    setOpened(false);
                  }}
                >
                  <Text size="sm">{option.label.replace("_", " ")}</Text>
                </Menu.Item>
              )
          )}
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};
