import { Badge } from "@mantine/core";
import { format, isPast, isToday, formatDistanceToNow } from "date-fns";
import { IconAlertTriangleFilled, IconClock } from "@tabler/icons-react";
import styles from "./DeadlineField.module.css";

interface DeadlineProps {
  deadline: string;
}

export function DeadlineField({ deadline }: DeadlineProps) {
  if (!deadline) return null;

  const formattedDeadline = format(new Date(deadline), "yyyy-MM-dd HH:mm");
  const isDeadlinePast = isPast(new Date(deadline));
  const isDeadLineToday = isToday(new Date(deadline));
  const relativeTime = formatDistanceToNow(new Date(deadline));

  return isDeadlinePast || isDeadLineToday ? (
    <Badge
      tt="none"
      autoContrast
      color={isDeadLineToday ? "#FFE760" : "#FF7058"}
      className={styles.deadlineBadge}
      leftSection={<IconAlertTriangleFilled size={12} />}
    >
      {isDeadlinePast ? `${relativeTime} ago` : `in ${relativeTime}`}
    </Badge>
  ) : (
    <Badge
      color="#F4F4FF"
      className={styles.deadlineBadge}
      leftSection={<IconClock size={14} />}
      tt="none"
      autoContrast
    >
      {formattedDeadline}
    </Badge>
  );
}
