import { useParams, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import { Button, Modal, Tabs } from "@mantine/core";
import { DataTable } from "mantine-datatable";
import { IconEdit } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { SpaceContext } from "../../contexts/SpaceContext";
import ProcessForm from "../forms/ProcessForm";
import { ProcessContext } from "../../contexts/ProcessContext";
import { Process, WorkPriority } from "../../gql/graphql";
import { createProcess, updateProcess } from "../operations/process";
import ProjectTable from "../tables/ProjectTable";

// Define the GraphQL query
const GET_SPACE_INFO = gql`
  query GetSpaceInfo($spaceId: ID!) {
    spaceById(id: $spaceId) {
      title
      processes {
        id
        title
        priority
        description
        deadline_units
        deadline_uom
      }
    }
  }
`;

const ModalStates = {
  OFF: "OFF",
  ADD: "ADD",
  EDIT: "EDIT",
};

export function SpaceDetails() {
  const { process, setProcess } = React.useContext(ProcessContext);
  const [tableSource, setTableSource] = useState<DataType[]>([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const { space, setSpace } = React.useContext(SpaceContext);
  const [modalState, setModalState] = useState(ModalStates.OFF);
  const { spaceId } = useParams(); // Get the spaceId from the route parameters
  // Use the useQuery hook to fetch data
  const { loading, error, data } = useQuery(GET_SPACE_INFO, {
    variables: { spaceId },
  });

  useEffect(() => {
    // Effect logic here.
    // For example:
    if (!loading && !error) {
      setTableSource(
        data.spaceById.processes.map((process: Process) => ({
          key: process.id,
          title: process.title,
          priority: process.priority,
          description: process.description,
          deadline_uom: process.deadline_uom,
          deadline_units: process.deadline_units,
        }))
      );
      setProcess({ cProcessId: "", cProcessName: "" });

      setSpace({
        cSpaceId: spaceId,
        cSpaceName: data.spaceById.title,
      } as any);
    }
  }, [data, loading, error]); // Dependency array

  if (loading) return <p>Loading...</p>;
  if (error) {
    if (
      error.networkError &&
      "statusCode" in error.networkError &&
      error.networkError.statusCode === 401
    ) {
      window.location.reload();
      return null;
    }
    return <p>Error: {error.message}</p>;
  }

  interface DataType {
    key: string;
    title: string;
    priority: WorkPriority;
    description: string;
    deadline_uom: string;
    deadline_units: number;
  }

  const columns = [
    {
      accessor: "title",
      title: "Title",
      render: (row: DataType) => (
        <Link to={`/process/${row.key}`} key={row.key}>
          {row.title}
        </Link>
      ),
    },
    {
      accessor: "priority",
      title: "Priority",
    },
    {
      accessor: "actions",
      title: "",
      width: 100,
      render: (row: DataType) => (
        <div style={{ height: "22px", display: "flex", alignItems: "center" }}>
          {hoveredRow === row.key && (
            <Button
              variant="subtle"
              size="xs"
              onClick={() => handleEditProcess(row)}
            >
              <IconEdit size={14} stroke={1.5} />
            </Button>
          )}
        </div>
      ),
    },
  ];

  const handleMouseEnter = (event: React.MouseEvent<HTMLTableElement>) => {
    const row = event.currentTarget.closest("tr");
    if (row) {
      const key = row.getAttribute("data-key");
      if (key) {
        setHoveredRow(key);
      }
    }
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const handleAddProcess = () => {
    setModalState(ModalStates.ADD);
  };

  const handleEditProcess = (entity: any) => {
    setSelectedRow(entity);
    setModalState(ModalStates.EDIT);
  };

  const handleCancel = () => {
    setModalState(ModalStates.OFF);
  };

  const handleSaveProcess = async (process: any) => {
    if (modalState === ModalStates.ADD) {
      try {
        let withSpace = { ...process, spaceId: spaceId };
        const result = await createProcess(withSpace);

        const newData: DataType = {
          key: result.data.createProcess.id,
          title: result.data.createProcess.title,
          priority: result.data.createProcess.priority,
          description: result.data.createProcess.description,
          deadline_uom: result.data.createProcess.deadline_uom,
          deadline_units: result.data.createProcess.deadline_units,
        };

        setTableSource([...tableSource, newData]);

        // Handle the result if needed
        console.log("Mutation result:", result);
      } catch (error) {
        // Handle the error if the mutation fails
        console.error("Mutation error:", error);
      }
    } else if (modalState === ModalStates.EDIT) {
      try {
        const result = await updateProcess(process);
        if (result.data.updateProcess.id === process.id) {
          setTableSource((tableSource) =>
            tableSource.map((item) =>
              item.key === process.id
                ? {
                    key: result.data.updateProcess.id,
                    title: result.data.updateProcess.title,
                    priority: result.data.updateProcess.priority,
                    description: result.data.updateProcess.description,
                    deadline_units: result.data.updateProcess.deadline_units,
                    deadline_uom: result.data.updateProcess.deadline_uom,
                  }
                : item
            )
          );
          // Handle the result if needed
          console.log("Mutation result:", result);
        } else {
          console.error("Error updating process");
          throw new Error("Error updating process");
        }
      } catch (error) {
        // Handle the error if the mutation fails
        console.error("Mutation error:", error);
      }
    }
    setModalState(ModalStates.OFF);
  };

  const TableHeader: React.FC<{ headerTitle: string }> = ({ headerTitle }) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <div className="title">{headerTitle}</div>
        <Button onClick={handleAddProcess}>New</Button>
      </div>
    );
  };

  const ProcessesTab = (
    <Tabs.Panel value="processes">
      <div>
        <DataTable
          columns={columns}
          records={tableSource}
          title="Processes"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        <Modal
          zIndex={10000}
          title={
            modalState === ModalStates.ADD ? "Add Process" : "Edit Process"
          }
          opened={modalState !== ModalStates.OFF}
          onClose={handleCancel}
          withCloseButton={false}
        >
          <ProcessForm
            onSave={handleSaveProcess}
            onCancel={handleCancel}
            initialState={modalState === ModalStates.ADD ? "ADD" : "EDIT"}
            selectedRow={selectedRow}
          />
        </Modal>
      </div>
    </Tabs.Panel>
  );

  const CyclesTab = (
    <Tabs.Panel value="cycles">
      <ProjectTable id={spaceId ? spaceId : ""} cyclesBy="SPACE" />
    </Tabs.Panel>
  );

  return (
    <Tabs defaultValue="cycles">
      <Tabs.List>
        <Tabs.Tab value="cycles">Cycles</Tabs.Tab>
        <Tabs.Tab value="processes">Processes</Tabs.Tab>
      </Tabs.List>
      {CyclesTab}
      {ProcessesTab}
    </Tabs>
  );
}

export default SpaceDetails;
