import { Badge } from "@mantine/core";
import styles from "./PriorityField.module.css";
import { IconSquareFilled } from "@tabler/icons-react";

interface PriorityColumnFieldProps {
  priority: string;
}
export function PriorityField({ priority }: PriorityColumnFieldProps) {
  const getPriorityColor = (priority: string): string => {
    switch (priority) {
      case "Low":
        return "#89FA60";
      case "Medium":
        return "#FFE760";
      case "High":
        return "#FF7058";
      case "Critical":
        return "red";
      default:
        return "gray";
    }
  };

  return (
    <Badge
      tt="none"
      leftSection={
        <IconSquareFilled size={8} color={getPriorityColor(priority)} />
      }
      radius="xl"
      className={styles.statusBadge}
      color="white"
    >
      {" " + priority + " "}
    </Badge>
  );
}
