import React, { useState } from "react";
import { NumberInput, Select, Group, Stack } from "@mantine/core";

interface TimePeriodProps {
  label?: string;
  units?: number | string;
  uom?: string;
  onChange: (units: number | undefined, uom: string | undefined) => void;
}

export const TimePeriod: React.FC<TimePeriodProps> = ({
  label,
  units,
  uom,
  onChange,
}) => {
  const initialUnits = typeof units === "string" ? Number(units) : units;
  const [getUnits, setUnits] = useState<number | undefined>(
    Number.isNaN(initialUnits) ? undefined : initialUnits
  );
  const [getUom, setUom] = useState<string | undefined>(uom);

  // useEffect(() => {
  //   setUnits(units);
  //   setUom(uom);
  // }, [units, uom]);

  const onUnitsChange = (value: number | string) => {
    const newUnits = typeof value === "number" ? value : undefined;
    setUnits(newUnits);
    onChange?.(newUnits, getUom);
  };

  const onUomChange = (newUom: string | null) => {
    const value = newUom || undefined;
    setUom(value);
    onChange?.(getUnits, value);
  };

  return (
    <Stack gap="xs">
      {label && (
        <div style={{ fontSize: "14px", fontWeight: 500 }}>{label}</div>
      )}
      <Group gap="sm">
        <NumberInput
          placeholder="3"
          value={getUnits}
          onChange={onUnitsChange}
          w={100}
        />
        <Select
          value={getUom}
          w={120}
          placeholder="Days"
          onChange={onUomChange}
          data={[
            { value: "Hours", label: "Hours" },
            { value: "Days", label: "Days" },
            { value: "Weeks", label: "Weeks" },
            { value: "Months", label: "Months" },
            { value: "Years", label: "Years" },
          ]}
          styles={{ dropdown: { zIndex: 10001 } }}
        />
      </Group>
    </Stack>
  );
};
