import { useState, useEffect } from "react";
import {
  Button,
  Select,
  Box,
  Group,
  Paper,
  Stack,
  MultiSelect,
  TextInput,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { DatePickerInput, TimeInput } from "@mantine/dates";
import DaysOfWeek from "../../components/DaysOfWeek";
import DaysOfMonth from "../../components/DaysOfMonth";
import MonthsOfYear from "../../components/MonthsOfYear";
import { format, parse } from "date-fns";

interface TriggerScheduleFormProps {
  onSave: (values: any) => void;
  onCancel: () => void;
  initialState: "ADD" | "EDIT";
  data: any;
}

export const TriggerScheduleForm: React.FC<TriggerScheduleFormProps> = ({
  onSave,
  onCancel,
  initialState,
  data,
}) => {
  const [isEditing, setIsEditing] = useState(initialState === "EDIT");

  const form = useForm({
    initialValues: {
      processId: data.id,
      triggerId: data.triggerSchedule?.id,
      frequency: "Daily",
      dayOfWeek: [] as string[],
      dayOfMonth: [] as number[],
      monthOfYear: [] as string[],
      timeOfDay: ["00:00:00"],
      singleTime: "00:00",
    },
  });

  useEffect(() => {
    if (initialState === "EDIT" && data && data.triggerSchedule) {
      form.setValues({
        processId: data.id,
        triggerId: data.triggerSchedule?.id,
        frequency: data.triggerSchedule?.frequency || "Daily",
        dayOfWeek: data.triggerSchedule?.dayOfWeek || [],
        dayOfMonth: data.triggerSchedule?.dayOfMonth || [],
        monthOfYear: data.triggerSchedule?.monthOfYear || [],
        timeOfDay: data.triggerSchedule?.timeOfDay || ["00:00"],
        singleTime: data.triggerSchedule?.timeOfDay?.[0] || "00:00",
      });
      setIsEditing(true);
    } else {
      form.setValues({
        processId: data.id,
        triggerId: data.triggerSchedule?.id || null,
        frequency: "Daily",
        dayOfWeek: [],
        dayOfMonth: [],
        monthOfYear: [],
        timeOfDay: ["00:00"],
        singleTime: "00:00",
      });
    }
  }, [data, initialState]);

  if (!data) {
    return null;
  }

  const handleSave = () => {
    const values = form.values;
    if (values.singleTime !== "00:00") {
      values.timeOfDay = [values.singleTime];
    }
    onSave(values);
    onCancel();
  };

  return (
    <Paper p="md">
      <form onSubmit={form.onSubmit(handleSave)}>
        <Stack gap="md">
          <Select
            {...form.getInputProps("frequency")}
            label="Frequency"
            placeholder="Select frequency"
            data={[
              { value: "Daily", label: "Daily" },
              { value: "Weekly", label: "Weekly" },
              { value: "Monthly", label: "Monthly" },
              { value: "Yearly", label: "Yearly" },
            ]}
            required
          />

          {form.values.frequency === "Weekly" && (
            <Box>
              <Text
                fw={500}
                mb={8}
                size="sm"
                className="mantine-InputWrapper-label"
              >
                Days of the Week
              </Text>
              <DaysOfWeek
                {...form.getInputProps("dayOfWeek")}
                days={form.values.dayOfWeek}
                // onChange={handleDayOfWeekChange}
              />
            </Box>
          )}

          {form.values.frequency === "Yearly" && (
            <Box>
              <Text
                fw={500}
                mb={8}
                size="sm"
                className="mantine-InputWrapper-label"
              >
                Months of the Year
              </Text>
              <MonthsOfYear
                {...form.getInputProps("monthOfYear")}
                months={form.values.monthOfYear}
              />
            </Box>
          )}

          {(form.values.frequency === "Monthly" ||
            form.values.frequency === "Yearly") && (
            <Box>
              <Text
                fw={500}
                mb={8}
                size="sm"
                className="mantine-InputWrapper-label"
              >
                Days of the Month
              </Text>
              <DaysOfMonth
                {...form.getInputProps("dayOfMonth")}
                days={form.values.dayOfMonth}
              />
            </Box>
          )}

          <TimeInput
            {...form.getInputProps("singleTime")}
            label="Time of Day"
          ></TimeInput>

          <Group justify="flex-end" mt="md">
            <Button variant="outline" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" color="blue">
              {isEditing ? "Save Changes" : "Add Schedule"}
            </Button>
          </Group>
        </Stack>
      </form>
    </Paper>
  );
};

export default TriggerScheduleForm;
