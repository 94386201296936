import { Text, Button, Group } from "@mantine/core";
import { modals } from "@mantine/modals";

export function openDeleteConfirmation(
  entityName: string,
  entityType: string,
  onConfirm: () => void
) {
  modals.openConfirmModal({
    title: `Delete ${entityType}`,
    centered: true,
    children: (
      <Text size="sm">
        Are you sure you want to delete {entityType.toLowerCase()} "{entityName}
        "? This action cannot be undone.
      </Text>
    ),
    labels: { confirm: "Delete", cancel: "Cancel" },
    confirmProps: { color: "red" },
    cancelProps: { variant: "subtle", color: "gray" },
    closeOnClickOutside: false,
    withCloseButton: true,
    onConfirm,
  });
}
