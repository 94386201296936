import { useState, useEffect } from "react";
import { TextInput, Button, Select, Stack, Box } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { WorkPriority, WorkStatus } from "../../gql/graphql";
import { Description } from "../../components/Description";

interface ProcessCycleFormProps {
  onSave: (values: any) => void;
  onCancel: () => void;
  initialState: "ADD" | "EDIT";
  selectedRow: any;
}

export const ProcessCycleForm: React.FC<ProcessCycleFormProps> = ({
  onSave,
  onCancel,
  initialState,
  selectedRow,
}) => {
  const [isEditing] = useState(initialState === "EDIT");

  const form = useForm({
    initialValues: {
      id: null,
      title: "",
      priority: WorkPriority.Medium,
      description: "",
      status: WorkStatus.ToDo,
      deadline: "",
      deadline_date: new Date(),
    },
    validate: {
      title: (value) => (value ? null : "Please enter a title"),
      priority: (value) => (value ? null : "Please select a priority"),
    },
  });

  useEffect(() => {
    if (initialState === "EDIT" && selectedRow) {
      form.setValues({
        id: selectedRow.key,
        title: selectedRow.cycle,
        status: selectedRow.status,
        priority: selectedRow.priority,
        deadline: selectedRow.deadline,
        description: selectedRow.description || "",
      });
    }
  }, [initialState, selectedRow]);

  const handleSave = async () => {
    const validation = form.validate();
    if (!validation.hasErrors) {
      onSave(form.values);
      onCancel();
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSave();
      }}
    >
      <Stack gap="md">
        {/* <TextInput type="hidden" {...form.getInputProps("id")} />
        <TextInput type="hidden" {...form.getInputProps("status")} />
        <TextInput type="hidden" {...form.getInputProps("deadline")} /> */}

        <TextInput
          label="Title"
          placeholder="Enter title"
          required
          {...form.getInputProps("title")}
        />

        <Description
          markdown={initialState === "EDIT" ? selectedRow?.description : ""}
          onChange={(markdown: string) =>
            form.setFieldValue("description", markdown)
          }
          showToolbar={form.isDirty()}
        />

        <Select
          label="Default Priority"
          placeholder="Select priority"
          required
          data={[
            { value: "Low", label: "Low" },
            { value: "Medium", label: "Medium" },
            { value: "High", label: "High" },
            { value: "Critical", label: "Critical" },
          ]}
          defaultValue="Medium"
          {...form.getInputProps("priority")}
          styles={{ dropdown: { zIndex: 20000 } }}
        />

        <DateTimePicker
          label="Deadline"
          placeholder="Select deadline"
          valueFormat="YYYY-MM-DD HH:mm"
          {...form.getInputProps("deadline_date")}
          onChange={(date) => {
            if (date) {
              form.setFieldValue("deadline_date", date);
              form.setFieldValue("deadline", date.toISOString());
            }
          }}
        />
        <Box style={{ display: "flex", gap: "8px" }}>
          <Button
            variant="outline"
            onClick={onCancel}
            // disabled={form.isDirty() === false}
          >
            {isEditing ? "Undo" : "Cancel"}
          </Button>
          <Button
            type="submit"
            variant="filled"
            disabled={form.isDirty() === false}
            style={{ marginLeft: "auto" }}
          >
            {isEditing ? "Save Changes" : "Add Project"}
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default ProcessCycleForm;
