import React from "react";
import { useParams } from "react-router-dom";
import { SpaceContext } from "../../contexts/SpaceContext";
// import CyclesTable from "../tables/CyclesTable";
import { ProcessContext } from "../../contexts/ProcessContext";
import Spaces from "./components/Spaces";
import ProjectTable from "../tables/ProjectTable";

// Create the component
export function TenantSpaces() {
  const { tenantId } = useParams();
  const effectiveTenantId = tenantId ?? localStorage.getItem("user") ?? "";
  const { setSpace } = React.useContext(SpaceContext);
  const { setProcess } = React.useContext(ProcessContext);
  // Add state for selected space
  const [selectedSpaceId, setSelectedSpaceId] = React.useState<string>("");

  // Reset space and process when this component mounts
  React.useEffect(() => {
    setSpace({ cSpaceId: "", cSpaceName: "" });
    setProcess({ cProcessId: "", cProcessName: "" });
  }, [setSpace, setProcess]);

  // Handle space selection with toggle functionality
  const handleSpaceClick = (spaceId: string) => {
    // If clicking the already selected space, deselect it
    if (selectedSpaceId === spaceId) {
      setSelectedSpaceId("");
      setSpace({ cSpaceId: "", cSpaceName: "" });
    } else {
      // Otherwise select the new space
      setSelectedSpaceId(spaceId);
      setSpace({ cSpaceId: spaceId, cSpaceName: "" });
    }
  };

  return (
    <div>
      <Spaces
        tenantId={effectiveTenantId}
        onSelectSpace={handleSpaceClick}
        selectedSpaceId={selectedSpaceId}
      />
      <div style={{ marginTop: "16px" }}>
        {selectedSpaceId ? (
          // If a space is selected, filter by space
          <ProjectTable id={selectedSpaceId} cyclesBy="SPACE" />
        ) : (
          // Otherwise, filter by tenant
          <ProjectTable id={effectiveTenantId || ""} cyclesBy="TENANT" />
        )}
      </div>
    </div>
  );
}

export default TenantSpaces;
