import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { gql } from "graphql-tag";
import { Space } from "../../../gql/graphql";
import SpaceCard from "./SpaceCard";
import { checkAuthError } from "../../../auth/signin";
import { ActionIcon, Modal } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import SpaceForm from "../../forms/SpaceForm";

const GET_TENANT_SPACES = gql`
  query GetTenantInfo($tenantId: ID!) {
    tenantById(id: $tenantId) {
      title
      spaces {
        id
        title
        description
        processes {
          id
          title
          description
          # cycles {
          #   id
          #   status
          # }
        }
      }
    }
  }
`;

const CREATE_SPACE = gql`
  mutation CreateSpace(
    $title: String!
    $description: String
    $tenantId: String!
  ) {
    createSpace(title: $title, description: $description, tenantId: $tenantId) {
      id
      title
      description
    }
  }
`;

interface SpacesProps {
  tenantId: string;
  onSelectSpace?: (spaceId: string) => void;
  selectedSpaceId?: string;
}

export function Spaces({
  tenantId,
  onSelectSpace,
  selectedSpaceId,
}: SpacesProps) {
  const [modalOpen, setModalOpen] = useState(false);

  // Use the useQuery hook to fetch data
  const { loading, error, data, refetch } = useQuery(GET_TENANT_SPACES, {
    variables: { tenantId },
  });

  const [createSpace, { loading: createLoading }] = useMutation(CREATE_SPACE, {
    onCompleted: () => {
      setModalOpen(false);
      refetch();
    },
    onError: (error) => {
      console.error("Error creating space:", error);
    },
  });

  if (loading) return <p>Loading spaces...</p>;

  if (error) {
    checkAuthError(error);
    return <p>Error loading spaces: {error.message}</p>;
  }

  const spaces = data.tenantById.spaces;

  const handleAddSpace = () => {
    setModalOpen(true);
  };

  const handleSaveSpace = (values: any) => {
    createSpace({
      variables: {
        title: values.title,
        description: values.description,
        tenantId: tenantId,
      },
    });
  };

  const handleSpaceClick = (e: React.MouseEvent, spaceId: string) => {
    if (onSelectSpace) {
      e.preventDefault();
      onSelectSpace(spaceId);
    }
  };

  return (
    <>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
        {spaces.map((space: Space) => (
          <div
            key={space.id}
            onClick={(e) => handleSpaceClick(e, space.id)}
            style={{ cursor: onSelectSpace ? "pointer" : "default" }}
          >
            <SpaceCard
              space={space}
              isSelected={selectedSpaceId === space.id}
            />
          </div>
        ))}

        <ActionIcon
          onClick={handleAddSpace}
          style={{
            width: 80,
            height: 150,
            borderRadius: 24,
            padding: 24,
            backgroundColor: "#000102",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          styles={{
            root: {
              "&:hover": {
                backgroundColor: "#1a1a1a",
              },
            },
          }}
        >
          <IconPlus size={32} color="white" />
        </ActionIcon>
      </div>

      <Modal
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Create New Space"
        centered
        size="lg"
      >
        <SpaceForm
          onSave={handleSaveSpace}
          onCancel={() => setModalOpen(false)}
          initialState="ADD"
          tenantId={tenantId}
        />
      </Modal>
    </>
  );
}

export default Spaces;
