const schemaArray = [
  {
    actionType: "PROMPT",
    schema: {
      type: "object",
      properties: {
        prompt: {
          type: "string",
          title: "Prompt Text",
          description: "The prompt text to be used",
          minLength: 1,
          maxLength: 2000,
        },
      },
      required: ["prompt"],
    },
  },
  {
    actionType: "LAUNCH_PROCESS",
    schema: {
      type: "object",
      properties: {
        process_id: {
          type: "string",
          title: "Process ID",
          format: "uuid",
          description: "The UUID of the process to be launched",
        },
      },
      required: ["process_id"],
    },
  },
  {
    actionType: "LAMBDA",
    schema: {
      type: "object",
      properties: {
        function_name: {
          type: "string",
          title: "Lambda Function Name",
          description: "The name or ARN of the Lambda function to invoke",
        },
        region: {
          type: "string",
          title: "AWS Region",
          description: "AWS region where the Lambda function is deployed",
          pattern: "^[a-z]{2}-(gov-)?[a-z]+-\\d{1}$",
        },
      },
      required: ["function_name", "region"],
    },
  },
];

export function getSchemaForActionType(actionType: string) {
  const schemaItem = schemaArray.find((item) => item.actionType === actionType);
  return schemaItem ? schemaItem.schema : null;
}

export {};
