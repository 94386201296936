import React from "react";
import { Card, Text, Group, rem, ActionIcon } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
import { Space } from "../../../gql/graphql";

interface SpaceCardProps {
  space: Space;
  isSelected?: boolean;
}

export function SpaceCard({ space, isSelected = false }: SpaceCardProps) {
  return (
    <Card
      padding="md"
      radius="24px"
      withBorder
      style={{
        width: 283,
        height: 150,
        position: "relative",
        border: isSelected ? "1px solid #9B51E0" : undefined,
      }}
    >
      <Group justify="space-between" mb="xs">
        <Text
          fw={400}
          size="16px"
          lh="19.5px"
          style={{ fontFamily: "Montserrat", letterSpacing: "0%" }}
        >
          {space.title}
        </Text>
        <ActionIcon variant="subtle" color="gray">
          <IconArrowRight size={rem(16)} />
        </ActionIcon>
      </Group>

      <Text
        style={{
          fontFamily: "Montserrat",
          fontWeight: 500,
          fontSize: "48px",
          lineHeight: "48px",
          letterSpacing: "0%",
        }}
      >
        {"0"}
        {/* You can add a count here if available */}
      </Text>

      <Text
        style={{
          fontFamily: "Montserrat",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "17.07px",
          letterSpacing: "0%",
          marginTop: "8px",
          color: "#49505B",
        }}
      >
        Projects
      </Text>

      <Text color="dimmed" size="sm">
        {space.description}
      </Text>
    </Card>
  );
}

export default SpaceCard;
